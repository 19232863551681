import { useMemo } from "react"
import { useDispatch, shallowEqual } from "react-redux"
import { useTypedSelector } from "src/models/redux"
import { Actions as uiActions } from "src/models/ui"
import { UiTypes } from "src/types"

const useToasts = (): [
  UiTypes.Toast[],
  {
    addToast: (toast: UiTypes.Toast) => UiTypes.UiToastThunkAction
    removeToast: (toast: UiTypes.Toast) => UiTypes.UiToastThunkAction
  }
] => {
  const dispatch = useDispatch()
  const toastList = useTypedSelector((state) => state.ui.toastList, shallowEqual)

  const handlers = useMemo(
    () => ({
      addToast: (toast: UiTypes.Toast) => dispatch(uiActions.addToast(toast)),
      removeToast: (toast: UiTypes.Toast) => dispatch(uiActions.removeToast(toast)),
    }),
    []
  )

  return [toastList, handlers]
}

export default useToasts
