import { useCallback } from "react"
import * as R from "ramda"
import { useDispatch, shallowEqual } from "react-redux"
import { Actions as challengesActions, selectTaskStateForChallenge } from "src/models/challenges"
import { useTypedSelector } from "src/models/redux"
import { coerceChallengeId } from "src/utils/challenges"
import sqlTasks from "src/data/sqlTasks"
import { noop } from "src/utils/misc"

import { ChallengeTypes } from "src/types"

const defaultTaskState = { tasks: {}, currentTask: -1 }

const taskStateIsInvalid = (taskState: ChallengeTypes.TaskCompletion) => {
  return R.isEmpty(taskState) || R.isNil(taskState) || taskState?.currentTask === -1
}

export default function useCurrentChallengeTasks(challengeId: string | undefined) {
  const dispatch = useDispatch()

  const sqlTasksMapping = typeof challengeId !== "undefined" ? sqlTasks[coerceChallengeId(challengeId)] : null
  const taskState: ChallengeTypes.TaskCompletion = useTypedSelector(
    (state) => (!R.isNil(challengeId) ? selectTaskStateForChallenge(state, challengeId) : defaultTaskState),
    shallowEqual
  )
  const activeTaskId = taskStateIsInvalid(taskState) ? 0 : taskState?.currentTask
  const completeTask = useCallback(
    (taskId: number) =>
      typeof challengeId !== "undefined" ? dispatch(challengesActions.completeTask(challengeId, taskId)) : noop(),
    [challengeId]
  )

  return { tasks: sqlTasksMapping ? sqlTasksMapping.tasks : [], taskState, activeTaskId, completeTask }
}
