import { css, FlattenSimpleInterpolation } from "styled-components"

export type MediaQuerySizes = "mobile" | "small" | "medium" | "tablet" | "large" | "desktop" | "xl"

const sizes: Record<string, string> = {
  desktop: "1400px",
  large: "1150px",
  tablet: "900px",
  medium: "700px",
  small: "500px",
  mobile: "280px",
}

// Iterate through the sizes and create a media template
const mediaTemplates = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      @media only screen and (min-width: ${sizes[label]}) {
        ${css(literals, ...placeholders)}
      }
    `.join("")
  return acc
}, {} as Record<keyof typeof sizes, (l: TemplateStringsArray, ...p: any[]) => FlattenSimpleInterpolation | string>)

export default mediaTemplates
