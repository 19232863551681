/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { LoadingScreen } from "src/components"
import { SQLiteDatabaseProvider } from "src/context/sqliteDb"
import configureStore from "src/models/store"

const { store, persistor } = configureStore()

export const wrapRootElement = ({ element }) => {
  const ConnectedRootElement = (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <SQLiteDatabaseProvider>
          <>{element}</>
        </SQLiteDatabaseProvider>
      </PersistGate>
    </Provider>
  )

  return ConnectedRootElement
}
