import React from "react"
import * as R from "ramda"
import { sleep } from "src/utils/async"
import initSqlJs, { SqlJsStatic } from "sql.js"
import { initializeUsersDatabase, initializeBlogDb } from "src/utils/seed"

import { SQLiteDbTypes } from "src/types"

const SQLiteDatabaseContext = React.createContext<SQLiteDbTypes.SQLiteContextType | null>(null)

type DBBuffers = { moviesDb?: ArrayBuffer; countriesDb?: ArrayBuffer; olympicsDb?: ArrayBuffer }

export const SQLiteDatabaseProvider: React.FC = ({ children }) => {
  const [StaticSQL, setSql] = React.useState<SqlJsStatic | null>(null)
  const [db, setDb] = React.useState<SQLiteDbTypes.SQLiteDatabase | null>(null)
  const [moviesDb, setMoviesDb] = React.useState<SQLiteDbTypes.SQLiteDatabase | null>(null)
  const [countriesDb, setCountriesDb] = React.useState<SQLiteDbTypes.SQLiteDatabase | null>(null)
  const [olympicsDb, setOlympicsDb] = React.useState<SQLiteDbTypes.SQLiteDatabase | null>(null)
  const [usersDb, setUsersDb] = React.useState<SQLiteDbTypes.SQLiteDatabase | null>(null)
  const [blogDb, setBlogDb] = React.useState<SQLiteDbTypes.SQLiteDatabase | null>(null)
  const [dbBuffers, setDbBuffers] = React.useState<DBBuffers>({})
  const [err, setErr] = React.useState<string | null>(null)
  const [initializing, setInitializing] = React.useState(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [results, setResults] = React.useState<SQLiteDbTypes.SQLiteQueryResults>(null)

  const initializeDatabase = React.useCallback(async () => {
    setInitializing(true)

    try {
      const sqlPromise = initSqlJs({
        locateFile: (file) => `/${file}`,
      })
      const moviesDataPromise = fetch("/movies.sqlite").then((res) => res.arrayBuffer())
      const countriesDataPromise = fetch("/world_bank_countries.sqlite").then((res) => res.arrayBuffer())
      const olympicsDataPromise = fetch("/winter_olympics.sqlite").then((res) => res.arrayBuffer())
      const [SQL, countriesBuf, moviesBuf, olympicsBuf] = await Promise.all([
        sqlPromise,
        countriesDataPromise,
        moviesDataPromise,
        olympicsDataPromise,
      ])
      setSql(SQL)
      setDbBuffers({
        moviesDb: moviesBuf,
        countriesDb: countriesBuf,
        olympicsDb: olympicsBuf,
      })

      const newMoviesDb = new SQL.Database(new Uint8Array(moviesBuf))
      const newCountriesDb = new SQL.Database(new Uint8Array(countriesBuf))
      const newOlympicsDb = new SQL.Database(new Uint8Array(olympicsBuf))
      const newDb = new SQL.Database()
      const newUsersDb = new SQL.Database()
      const newBlogDb = new SQL.Database()

      initializeUsersDatabase(newUsersDb)
      initializeBlogDb(newBlogDb)

      setMoviesDb(newMoviesDb)
      setCountriesDb(newCountriesDb)
      setOlympicsDb(newOlympicsDb)
      setDb(newDb)
      setUsersDb(newUsersDb)
      setBlogDb(newBlogDb)
    } catch (err) {
      console.log({ err })
      setErr(err)
    } finally {
      setInitializing(false)
    }
  }, [])

  React.useEffect(() => {
    initializeDatabase()
  }, [])

  const exec = async (sql: string) => {
    setIsProcessing(true)

    try {
      if (!R.isNil(db)) {
        const results = db.exec(sql) // an array of objects is returned
        await sleep(500)
        setResults(results)
        setErr(null)
      } else {
        setErr("Database has not been initialized yet.")
      }
    } catch (err) {
      // exec throws an error when the SQL statement is invalid
      setErr(err)
    } finally {
      setIsProcessing(false)
    }
  }

  const refreshDb = React.useCallback(
    (dbName = "db") => {
      if (R.isNil(StaticSQL)) return

      if (dbName === "db") {
        const newDb = new StaticSQL.Database()
        setDb(newDb)
      }
      if (dbName === "moviesDb") {
        if (!R.isNil(dbBuffers) && !R.isEmpty(dbBuffers)) {
          if (!R.isNil(dbBuffers.moviesDb)) {
            const newMoviesDb = new StaticSQL.Database(new Uint8Array(dbBuffers.moviesDb))
            setMoviesDb(newMoviesDb)
          }
        }
      }
      if (dbName === "countriesDb") {
        if (!R.isNil(dbBuffers) && !R.isEmpty(dbBuffers)) {
          if (!R.isNil(dbBuffers.countriesDb)) {
            const newCountriesDb = new StaticSQL.Database(new Uint8Array(dbBuffers.countriesDb))
            setCountriesDb(newCountriesDb)
          }
        }
      }
      if (dbName === "olympicsDb") {
        if (!R.isNil(dbBuffers) && !R.isEmpty(dbBuffers)) {
          if (!R.isNil(dbBuffers.olympicsDb)) {
            const newOlympicsDb = new StaticSQL.Database(new Uint8Array(dbBuffers.olympicsDb))
            setOlympicsDb(newOlympicsDb)
          }
        }
      }
      if (dbName === "usersDb") {
        const newUsersDb = new StaticSQL.Database()
        initializeUsersDatabase(newUsersDb)
        setUsersDb(newUsersDb)
      }
      if (dbName === "blogDb") {
        const newBlogDb = new StaticSQL.Database()
        initializeBlogDb(newBlogDb)
        setBlogDb(newBlogDb)
      }
    },
    [StaticSQL, dbBuffers]
  )

  const contextValue = React.useMemo(() => {
    return {
      db,
      moviesDb,
      countriesDb,
      olympicsDb,
      usersDb,
      blogDb,
      err,
      exec,
      isProcessing,
      initializing,
      results,
      refreshDb,
    }
  }, [
    db,
    usersDb,
    moviesDb,
    countriesDb,
    olympicsDb,
    blogDb,
    err,
    exec,
    isProcessing,
    initializing,
    results,
    refreshDb,
  ])

  return (
    <SQLiteDatabaseContext.Provider value={contextValue}>
      <>{children}</>
    </SQLiteDatabaseContext.Provider>
  )
}

export const useSqliteDatabaseContext = () => React.useContext(SQLiteDatabaseContext)
