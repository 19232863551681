import { CommonTypes } from "src/types"

export const kebabCase = (str: string) => {
  const result = str.replace(/[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g, (match: string) => "-" + match.toLowerCase())
  return str[0] === str[0].toUpperCase() ? result.substring(1) : result
}

export const formatCategoryLink = (category: CommonTypes.Category) => kebabCase(category)
export const formatTagLink = (tag: CommonTypes.Tag) => kebabCase(tag)
export const capitalize = (str: string) => (str ? str[0].toUpperCase() + str.slice(1) : str)

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

export const formatCurrency = (dollars: string | number): string => {
  return Number.isNaN(dollars) ? String(dollars) : currencyFormatter.format(Number(dollars))
}

const populationFormatter = new Intl.NumberFormat("en-us")
export const formatPopulation = (pop: string | number): string => {
  return Number.isNaN(pop) ? String(pop) : populationFormatter.format(Number(pop))
}
