import { createStore, compose, applyMiddleware, Reducer } from "redux"
import rootReducer from "src/models/rootReducer"
import { persistStore, persistReducer } from "redux-persist"
import localForage from "localforage"
import thunk from "redux-thunk"

const persistConfig = {
  key: "root",
  storage: localForage,
  blacklist: ["challenges"],
  // whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer as Reducer)

const isBrowser = () => typeof window !== "undefined"

export default function configureStore(preloadedState = {}) {
  let composeEnhancers = compose

  // need to add redux dev tools on client side
  // but also handle ssr where window doesn't exist
  try {
    if (isBrowser() && process.env.NODE_ENV !== "production") {
      // @ts-ignore
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    }
  } catch (err) {
    // console.warn(err) // don't want to see window not existing errors
  }

  const createStoreWrapper = composeEnhancers(applyMiddleware(thunk))(createStore)

  // const store = createStoreWrapper(rootReducer)
  const store = createStoreWrapper(persistedReducer, preloadedState)
  const persistor = persistStore(store)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./rootReducer.ts", () => {
      const nextReducer = require("./rootReducer.ts")
      store.replaceReducer(nextReducer.default)
    })
  }

  // return store
  return { store, persistor }
}
