import { useStaticQuery, graphql } from "gatsby"
import { ChallengeTypes } from "src/types"

export interface ChallengesQueryResult {
  allMdx: {
    edges: Array<{ node: ChallengeTypes.ChallengeNodeFull }>
  }
}

export default function useChallenges(): ChallengesQueryResult {
  const data = useStaticQuery(graphql`
    query ChallengesQuery {
      allMdx(
        sort: { fields: frontmatter___id, order: ASC }
        filter: { frontmatter: { published: { eq: true } } } # only include published lessons
      ) {
        edges {
          node {
            wordCount {
              words
              sentences
              paragraphs
            }
            body
            tableOfContents
            timeToRead
            excerpt
            headings {
              value
              depth
            }
            frontmatter {
              id
              challengeId
              databaseName
              tags
              category
              title
              slug
              date
            }
            fields {
              slug
              date
              filePath
            }
          }
        }
      }
    }
  `)

  return data
}
