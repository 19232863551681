import { useStaticQuery, graphql } from "gatsby"
import { ChallengeTypes } from "src/types"

export interface LandingQueryResult {
  allMdx: {
    edges: Array<{ node: ChallengeTypes.ChallengeNodeFull }>
  }
}

export default function useLandingQuery(): LandingQueryResult {
  const data = useStaticQuery(graphql`
    query LandingQuery {
      allMdx(sort: { fields: frontmatter___id, order: ASC }, filter: { frontmatter: { published: { eq: true } } }) {
        edges {
          node {
            wordCount {
              words
              sentences
              paragraphs
            }
            body
            tableOfContents
            timeToRead
            excerpt
            headings {
              value
              depth
            }
            fields {
              slug
              date
              filePath
            }
            frontmatter {
              id
              challengeId
              databaseName
              slug
              title
              tags
              date
              category
            }
          }
        }
      }
    }
  `)

  return data
}
