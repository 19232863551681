import React from "react"
import { EuiBasicTable } from "@elastic/eui"
import styled from "styled-components"

const AggregateFunctionsTableWrapper = styled.div`
  max-width: 800px;
  margin-top: 1.5rem;

  & table {
    border: solid 1px #d3dae6;
    border-radius: 4px;

    & thead {
      background: #e3eaf6;
    }
  }
`

const items = [
  {
    "Aggregate Function Syntax": `MIN(x)`,
    Description: `The MIN() aggregate function returns the minimum non-NULL value of all values in the group.`,
  },
  {
    "Aggregate Function Syntax": `MAX(x)`,
    Description: "The MAX() aggregate function returns the maximum value of all values in the group.",
  },
  {
    "Aggregate Function Syntax": `SUM(x)`,
    Description: "The SUM() and TOTAL() aggregate functions return the sum of all non-NULL values in the group.",
  },
  {
    "Aggregate Function Syntax": `AVG(x)`,
    Description: "The AVG() function returns the average value of all non-NULL `x` within a group.",
  },
  {
    "Aggregate Function Syntax": `COUNT(x) / COUNT(*)`,
    Description:
      "The COUNT(x) function returns a count of the number of times that `x` is not NULL in a group. " +
      "The COUNT(*) function (with no arguments) returns the total number of rows in the group.",
  },
]

const columns = [
  {
    field: `Aggregate Function Syntax`,
    name: `Aggregate Function Syntax`,
    truncateText: false,
    width: "200px",
  },
  {
    field: `Description`,
    name: `Description`,
    truncateText: false,
  },
]

export default function AggregateFunctionsTable() {
  return (
    <AggregateFunctionsTableWrapper>
      <>
        <EuiBasicTable tableLayout="fixed" responsive={false} items={items} columns={columns} />
      </>
    </AggregateFunctionsTableWrapper>
  )
}
