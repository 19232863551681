import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { Actions as challengeActions } from "src/models/challenges"
import { useCompletedTasks, useLandingQuery } from "src/hooks"

export default function useProfile() {
  const dispatch = useDispatch()

  const data = useLandingQuery()
  const edges = data?.allMdx?.edges ?? []
  const { completedTasksMapping } = useCompletedTasks()

  const clearProgress = useCallback(() => dispatch(challengeActions.clearProgress()), [])

  const profile = { completedTasksMapping, edges, clearProgress }

  return profile
}
