import React from "react"
import * as R from "ramda"
import { EuiButtonIcon, EuiHealth, EuiSpacer, EuiFlexGroup, EuiFlexItem } from "@elastic/eui"
import { SQLEditor, SQLResultsTable } from "src/components"
import { useSqliteDatabaseContext } from "src/context/sqliteDb"
import { sleep } from "src/utils/async"
import { noop } from "src/utils/misc"
import styled from "styled-components"

import { SQLiteDbTypes } from "src/types"

const LessonInlineSQLEditorWrapper = styled.div`
  margin-top: 1.7149rem;

  & .refresh {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

interface ILessonInlineSQLEditorContainer {
  databaseName?: SQLiteDbTypes.DatabaseName
  includeRefresh?: boolean
  pageSize?: number
}
export default function LessonInlineSQLEditorContainer({
  databaseName = "db",
  includeRefresh = false,
  pageSize = 3,
}: ILessonInlineSQLEditorContainer) {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [results, setResults] = React.useState<SQLiteDbTypes.SQLiteQueryResults>(null)
  const [err, setErr] = React.useState<string | null>(null)
  const dbContext = useSqliteDatabaseContext()

  if (!R.isNil(dbContext)) {
    const { db, moviesDb, countriesDb, olympicsDb, blogDb, usersDb, initializing, refreshDb } = dbContext

    const dbToExec = { countriesDb, moviesDb, olympicsDb, blogDb, usersDb, db }[databaseName]

    const refreshDatabase = () => refreshDb(databaseName)

    if (initializing || R.isNil(dbToExec)) return null

    const exec = async (sql: string) => {
      setIsProcessing(true)

      try {
        const results = dbToExec.exec(sql) // an array of objects is returned
        await sleep(500)
        setResults(results)
        setErr(null)
      } catch (err) {
        // exec throws an error when the SQL statement is invalid
        setErr(err)
      } finally {
        setIsProcessing(false)
      }
    }

    return (
      <>
        <LessonInlineSQLEditor
          exec={exec}
          err={err}
          includeRefresh={includeRefresh}
          refreshDb={refreshDatabase}
          results={results}
          isProcessing={isProcessing}
          pageSize={pageSize}
        />
      </>
    )
  }

  return null
}

// Memoize this component so that provider value updates don't constantly cause rerenders
const LessonInlineSQLEditor = React.memo(
  ({
    exec,
    err,
    results,
    includeRefresh,
    isProcessing,
    pageSize,
    refreshDb,
  }: SQLiteDbTypes.ISQLiteContextConsumer & { includeRefresh?: boolean }) => {
    const [codeText, setCodeText] = React.useState("")

    const handleOnExecute = () => {
      if (!R.isNil(exec)) {
        exec(codeText)
      }
    }

    const renderStatus = () => {
      if (isProcessing) return <EuiHealth color="primary">Querying...</EuiHealth>
      if (err) return <EuiHealth color="danger">{err.toString()}</EuiHealth>
      return <EuiHealth color="success">Good to go</EuiHealth>
    }

    const coerceResults = () => {
      if (R.isNil(results)) return undefined

      if (results?.[0]?.columns?.length) return results[0]

      return { values: [], columns: [] }
    }

    const coercedResults = coerceResults()

    return (
      <LessonInlineSQLEditorWrapper>
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem>{renderStatus()}</EuiFlexItem>
          {includeRefresh ? (
            <EuiFlexItem>
              <span className="refresh">
                Refresh DB
                <EuiButtonIcon
                  aria-label="refresh button"
                  iconType="refresh"
                  onClick={() => (R.isNil(refreshDb) ? noop() : refreshDb("db"))}
                />
              </span>
            </EuiFlexItem>
          ) : null}
        </EuiFlexGroup>
        <EuiSpacer size="s" />
        <SQLEditor
          codeText={codeText}
          setCodeText={setCodeText}
          isProcessing={isProcessing}
          height="150px"
          handleOnExecute={handleOnExecute}
        />
        <SQLResultsTable results={coercedResults} pageSize={pageSize} />
      </LessonInlineSQLEditorWrapper>
    )
  }
)
