import React, { useState } from "react"

import {
  EuiAvatar,
  EuiBadge,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiHeader,
  EuiHeaderAlert,
  // EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiLink,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiPortal,
  EuiSpacer,
  // EuiSwitch,
  EuiTabs,
  EuiTab,
  EuiText,
  EuiTitle,
} from "@elastic/eui"
import { EuiCustomLink, DataDescriptionTable } from "src/components"
import { useUniqueId, useChallenges, useProfile } from "src/hooks"
import { renderDate } from "src/utils/dates"
import styled from "styled-components"

import * as constants from "src/utils/constants"

import { ChallengeTypes, SQLiteDbTypes } from "src/types"

const LessonExcerpt = styled.p`
  max-width: 400px;
`

const extractLessonNumberFromChallenge = (challenge: ChallengeTypes.ChallengeNodeFull) => {
  const { filePath } = challenge.fields
  const splitPath = filePath.split("-")
  return splitPath[1]
}

const createChallengesHeaderObjects = (challenges: ChallengeTypes.ChallengeNodeFull[]) => {
  return challenges.map((challenge: ChallengeTypes.ChallengeNodeFull) => ({
    title: challenge.frontmatter.title,
    text: challenge.excerpt,
    action: (
      <EuiCustomLink
        to={`/challenges${challenge.fields.slug}`}
        // target="_blank"
        // external
        // href="https://www.elastic.co/blog/how-to-create-manage-and-visualize-elasticsearch-rollup-data-in-kibana"
      >
        Go to Challenge
      </EuiCustomLink>
    ),
    date: renderDate(new Date(challenge.frontmatter.date)),
    badge: <EuiBadge color="hollow">{extractLessonNumberFromChallenge(challenge)}</EuiBadge>,
  }))
}

const HeaderUpdates = () => {
  const [databaseName, setDatbaseName] = useState<SQLiteDbTypes.DatabaseName>("moviesDb")
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const { allMdx } = useChallenges()

  const challenges = createChallengesHeaderObjects(allMdx.edges.map(({ node }) => node))

  const closeFlyout = () => {
    setIsFlyoutVisible(false)
  }

  const closePopover = () => {
    setIsPopoverVisible(false)
  }

  const showFlyout = () => {
    setIsFlyoutVisible(!isFlyoutVisible)
  }

  const showPopover = () => {
    setIsPopoverVisible(!isPopoverVisible)
  }

  const bellButton = (
    <EuiHeaderSectionItemButton
      aria-controls="challengesFeed"
      aria-expanded={isFlyoutVisible}
      aria-haspopup="true"
      aria-label={"Challenges feed: Updates available"}
      onClick={() => showFlyout()}
      // notification={true}
    >
      {/* <EuiIcon type="bell" /> */}
      <EuiIcon type="iInCircle" />
    </EuiHeaderSectionItemButton>
  )

  const cheerButton = (
    <EuiHeaderSectionItemButton
      aria-controls="headerPopoverNewsFeed"
      aria-expanded={isPopoverVisible}
      aria-haspopup="true"
      aria-label={"News feed: Updates available'"}
      onClick={showPopover}
      notification={1}
    >
      <EuiIcon type="cheer" />
    </EuiHeaderSectionItemButton>
  )

  const tabs = (["moviesDb", "olympicsDb", "countriesDb", "blogDb", "usersDb"] as SQLiteDbTypes.DatabaseName[]).map(
    (db: SQLiteDbTypes.DatabaseName) => (
      <EuiTab onClick={() => setDatbaseName(db)} isSelected={db === databaseName} key={db}>
        {constants.databaseNameToDisplayNameMapping[db]}
      </EuiTab>
    )
  )

  const flyout = (
    <EuiPortal>
      <EuiFlyout onClose={closeFlyout} size="l" id="headerFlyoutNewsFeed" aria-labelledby="flyoutSmallTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="s">
            <h2 id="flyoutSmallTitle">Column Descriptions</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiSpacer size="s" />
        <EuiFlyoutHeader>
          <EuiTabs size="m">{tabs}</EuiTabs>
        </EuiFlyoutHeader>
        <EuiSpacer size="s" />
        <EuiFlyoutBody>
          <DataDescriptionTable databaseName={databaseName} />
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="subdued" size="s">
                <p>Version 1.0</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiPortal>
  )

  const popover = (
    <EuiPopover
      id="headerPopoverNewsFeed"
      ownFocus
      repositionOnScroll
      button={cheerButton}
      isOpen={isPopoverVisible}
      closePopover={closePopover}
      panelPaddingSize="none"
    >
      <EuiPopoverTitle paddingSize="s">Challenges</EuiPopoverTitle>
      <div style={{ maxHeight: "40vh", overflowY: "auto", padding: 4 }}>
        <EuiSpacer size="s" />
        {challenges.map((challenge, i) => (
          <EuiHeaderAlert
            key={`challenge-${i}`}
            title={challenge.title}
            action={challenge.action}
            text={<LessonExcerpt>{challenge.text}</LessonExcerpt>}
            date={challenge.date}
            badge={challenge.badge}
          />
        ))}
      </div>
      <EuiPopoverFooter paddingSize="s">
        <EuiText color="subdued" size="s">
          <p>Version 1.0</p>
        </EuiText>
      </EuiPopoverFooter>
    </EuiPopover>
  )

  return (
    <>
      {bellButton}
      {popover}
      {isFlyoutVisible && flyout}
    </>
  )
}

interface IHeaderUserMenu {
  showDestroyModal: () => void
}
const HeaderUserMenu: React.FC<IHeaderUserMenu> = ({ showDestroyModal }) => {
  const id = useUniqueId()
  // const id = htmlIdGenerator()()
  const [isOpen, setIsOpen] = useState(false)

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={id}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar color="#0077cc" name="See Quell Learner" size="s" />
    </EuiHeaderSectionItemButton>
  )

  return (
    <EuiPopover
      id={id}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup gutterSize="m" className="euiHeaderProfile" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar color="#0077cc" name="See Quell Learner" size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>See Quell Learner</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <EuiCustomLink className="small-link" to="/profile">
                      Profile
                    </EuiCustomLink>
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={() => showDestroyModal()}>Clear Progress</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  )
}

export default function Navbar() {
  const { clearProgress } = useProfile()
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false)

  const closeDestroyModal = () => setIsDestroyModalVisible(false)
  const showDestroyModal = () => setIsDestroyModalVisible(true)

  const handleOnConfirmClearProgress = () => {
    clearProgress()
    closeDestroyModal()
  }

  return (
    <>
      <EuiHeader>
        <EuiHeaderSection grow={false}>
          <EuiHeaderSectionItem border="right">
            <EuiCustomLink to="/" isHeaderLogo iconType="database">
              SQL Challenges
            </EuiCustomLink>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHeaderSection side="right">
          <EuiHeaderSectionItem>
            <HeaderUpdates />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <HeaderUserMenu showDestroyModal={showDestroyModal} />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
      {isDestroyModalVisible ? (
        <EuiConfirmModal
          title="Clear Progress"
          onCancel={closeDestroyModal}
          onConfirm={handleOnConfirmClearProgress}
          cancelButtonText="No, don't do it"
          confirmButtonText="Yes, do it"
          buttonColor="danger"
          defaultFocusedButton="confirm"
        >
          <p>Note that all progress and saved work will be deleted, and you'll start with a fresh slate.</p>
          <p>Are you sure you want to do this?</p>
        </EuiConfirmModal>
      ) : null}
    </>
  )
}
