import React from "react"
import * as R from "ramda"
import {
  // EuiButton,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiIcon,
} from "@elastic/eui"
import { CompletedChallengeListing, LoadingScreen } from "src/components"
import { useSqliteDatabaseContext } from "src/context/sqliteDb"
import { useProfile } from "src/hooks"
import styled from "styled-components"

const LandingContainerWrapper = styled.div`
  width: 100%;
  height: 100vh;

  .under-construction {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    border-top: solid 1px rgb(222, 222, 222);
    border-bottom: solid 1px rgb(222, 222, 222);
  }

  .title-section {
    width: 100%;
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

const LandingMain = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: main;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
`

export default function ProfileContainer() {
  // initialize db on landing page
  const dbContext = useSqliteDatabaseContext()

  if (!R.isNil(dbContext)) {
    const { initializing } = dbContext

    if (initializing) return <LoadingScreen />

    return <Profile />
  }

  return <LoadingScreen />
}

const Profile = () => {
  const profile = useProfile()

  return (
    <LandingContainerWrapper>
      <LandingMain>
        <EuiPage restrictWidth>
          <EuiPageBody>
            <EuiPageHeader>
              <EuiPageHeaderSection className="title-section">
                <EuiTitle size="l" className="title">
                  <h1>Profile</h1>
                </EuiTitle>
              </EuiPageHeaderSection>
            </EuiPageHeader>
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Challenges Completed</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection />
              </EuiPageContentHeader>
              <EuiPageContentBody>
                <CompletedChallengeListing
                  edges={profile.edges}
                  completedTasksMapping={profile.completedTasksMapping}
                />
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>

        <EuiSpacer size="xxl" />
      </LandingMain>
    </LandingContainerWrapper>
  )
}
