import React from "react"
import { EuiAccordion, EuiText, EuiSpacer, EuiPanel, EuiCodeBlock } from "@elastic/eui"
import styled from "styled-components"

const LessonPracticeQuestionsWrapper = styled(EuiPanel)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

type Question = {
  question: string
  answer: string
}

interface ILessonPracticeQuestions {
  practiceQuestions: Question[]
}
export default function LessonPracticeQuestions({ practiceQuestions }: ILessonPracticeQuestions) {
  return (
    <LessonPracticeQuestionsWrapper>
      <EuiText>
        <h4>Practice questions</h4>
        <p>Click on any of the queries below to see a proposed solution</p>
      </EuiText>
      <EuiSpacer size="xl" />
      {practiceQuestions.map((item, i) => (
        <React.Fragment key={`accordian-${i}`}>
          <EuiAccordion id={`accordian-${i}`} buttonContent={item.question} paddingSize="m">
            <EuiText size="s">
              <EuiCodeBlock
                language="sql"
                fontSize="m"
                paddingSize="m"
                // overflowHeight={300}
              >
                {item.answer}
              </EuiCodeBlock>
            </EuiText>
          </EuiAccordion>
          <EuiSpacer size="l" />
        </React.Fragment>
      ))}
    </LessonPracticeQuestionsWrapper>
  )
}
