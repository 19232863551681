import React from "react"
import styled from "styled-components"
import { UiTypes } from "src/types"

const StyledSVG = styled.svg``

const Codepen: React.FC<UiTypes.IIconProps> = ({ className = "" }) => (
  <StyledSVG className={className} x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48">
    <g transform="translate(0, 0)">
      <path
        fill="#444444"
        d="M48,16.4C48,16.4,48,16.4,48,16.4c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1
	c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0L25.1,0.3c-0.7-0.5-1.6-0.5-2.3,0L0.9,15c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1
	c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3v14.6
	c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1
	c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
	c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0l21.9,14.6c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.8-0.1,1.1-0.3L47.1,33c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1
	c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
	c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3V16.7
	C48,16.6,48,16.5,48,16.4z M26.1,5.9l16.2,10.8L35,21.5l-8.9-6V5.9z M21.9,5.9v9.6l-8.9,6l-7.2-4.8L21.9,5.9z M4.1,20.5L9.3,24
	l-5.2,3.5V20.5z M21.9,42.1L5.8,31.3l7.2-4.8l8.9,6V42.1z M24,28.9L16.7,24l7.3-4.9l7.3,4.9L24,28.9z M26.1,42.1v-9.6l8.9-6l7.2,4.8
	L26.1,42.1z M43.9,27.5L38.7,24l5.2-3.5V27.5z"
      ></path>
    </g>
  </StyledSVG>
)

export default Codepen
