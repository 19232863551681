import React from "react"
import * as R from "ramda"
import {
  // EuiButton,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiIcon,
} from "@elastic/eui"
import { ChallengeListing, LoadingScreen } from "src/components"
import { useLandingQuery } from "src/hooks"
import { useSqliteDatabaseContext } from "src/context/sqliteDb"
import styled from "styled-components"

const LandingContainerWrapper = styled.div`
  width: 100%;
  height: 100vh;

  .under-construction {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    border-top: solid 1px rgb(222, 222, 222);
    border-bottom: solid 1px rgb(222, 222, 222);
  }

  .title-section {
    width: 100%;
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

const LandingMain = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: main;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
`

export default function LandingContainer() {
  // initialize db on landing page
  const dbContext = useSqliteDatabaseContext()

  if (!R.isNil(dbContext)) {
    const { initializing } = dbContext

    if (initializing) return <LoadingScreen />

    return <Landing />
  }

  return <LoadingScreen />
}

const Landing = () => {
  const data = useLandingQuery()

  const edges = data?.allMdx?.edges ? data.allMdx.edges : []

  return (
    <LandingContainerWrapper>
      <LandingMain>
        <EuiPage restrictWidth>
          <EuiPageBody>
            <EuiPageHeader>
              <EuiPageHeaderSection className="title-section">
                <EuiTitle size="l" className="title">
                  {/* <h1>SQL Playground Challenges</h1> */}
                  <h1>SQLChallenges.io</h1>
                </EuiTitle>
              </EuiPageHeaderSection>
              {/* <EuiPageHeaderSection>
                <EuiButton iconType="logoGithub" href="https://github.com/jastor11/sql-playground" fill>
                  Open in Github
                </EuiButton>
              </EuiPageHeaderSection> */}
            </EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle size="m">
                <span className="under-construction">
                  <EuiIcon type="devToolsApp" size="xxl" />
                  <h3>Under Active Development</h3>
                  <EuiIcon type="pipelineApp" size="xl" />
                </span>
              </EuiTitle>
            </EuiPageHeaderSection>
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Challenges</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection />
              </EuiPageContentHeader>
              <EuiPageContentBody>
                <ChallengeListing edges={edges} />
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>

        <EuiSpacer size="xxl" />
      </LandingMain>
    </LandingContainerWrapper>
  )
}
