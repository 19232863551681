import * as R from "ramda"

export const sortByFirstItem = R.sortBy((arr) => R.prop(0, arr))
export const sortBySecondItem = R.sortBy((arr) => R.prop(1, arr))

export const arraysAreEquivalentInOrderAndValue = (a: Array<any>, b: Array<any>): boolean => {
  return R.equals(a, b)
  // const outcome = R.length(a) === R.length(b) && R.isEmpty(R.symmetricDifference(a, b))

  // return outcome
}

export const arraysAreEquivalentInValue = (a: Array<any>, b: Array<any>): boolean => {
  const setA = [...new Set(a)]
  const setB = [...new Set(b)]
  setA.sort()
  setB.sort()
  return R.length(a) === R.length(b) && R.isEmpty(R.symmetricDifference(setA, setB))
}
