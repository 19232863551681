import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { Actions as codeEditorActions, getCodeTextForChallenge } from "src/models/codeEditor"
import { useTypedSelector } from "src/models/redux"

export type UseCodeEditorReturnType = [
  codeText: string,
  handlers: {
    setCodeText: (text: string, challengeId: string) => void
    resetCodeText: (challengeId: string) => void
  }
]
export default function useCodeEditor(challengeId: string): UseCodeEditorReturnType {
  const dispatch = useDispatch()
  const codeText = useTypedSelector((state) => getCodeTextForChallenge(state, challengeId))

  const handlers = useMemo(
    () => ({
      setCodeText: (text: string, challengeId: string) => dispatch(codeEditorActions.setCodeText(text, challengeId)),
      resetCodeText: (challengeId: string) => dispatch(codeEditorActions.resetCodeText(challengeId)),
    }),
    []
  )

  return [codeText, handlers]
}
