import initialState, { StateShape } from "./initialState"
import { Dispatch } from "redux"
import { useUniqueId } from "src/hooks"
import { ThunkAction } from "redux-thunk"
import { UiTypes } from "src/types"

/**
 * UI REDUCER
 *
 */
export default function uiReducer(state = initialState.ui, action: UiTypes.UiAction) {
  switch (action.type) {
    case UiTypes.UiActionType.ADD_UI_TOAST:
      return {
        ...state,
        toastList: [...state.toastList, action.toast],
      }
    case UiTypes.UiActionType.REMOVE_UI_TOAST:
      return {
        ...state,
        toastList: state.toastList.filter((t) => t.id !== action.toast.id),
      }
    case UiTypes.UiActionType.CLEAR_UI_STATE:
      return initialState.ui
    default:
      return state
  }
}

export type RemoveToastReturnType = ThunkAction<UiTypes.UiToastAction, StateShape, void, UiTypes.UiToastAction>
const removeToast = (toast: UiTypes.Toast): RemoveToastReturnType => {
  return (dispatch: Dispatch) => {
    return dispatch({ type: UiTypes.UiActionType.REMOVE_UI_TOAST, toast })
  }
}

// not an action - utility function for creating toasts
const createToast = (dispatch: Dispatch, toast: UiTypes.Toast): UiTypes.UiToastAction => {
  const toastId = useUniqueId("toast")

  return dispatch({ type: UiTypes.UiActionType.ADD_UI_TOAST, toast: { ...toast, id: toastId } })
}

export type AddToastReturnType = ThunkAction<UiTypes.UiToastAction | null, StateShape, void, UiTypes.UiToastAction>
const addToast = (toast: UiTypes.Toast): AddToastReturnType => {
  return (dispatch, getState) => {
    const { ui } = getState()
    const existingMessage = ui?.toastList?.map((t: UiTypes.Toast) => t.title).indexOf(toast.title) !== -1

    return !existingMessage ? createToast(dispatch, toast) : null
  }
}

export const Actions = { addToast, removeToast }
