import React from "react"
import { EuiBadge, EuiBasicTable, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiPanel } from "@elastic/eui"
import * as R from "ramda"
import * as constants from "src/utils/constants"

import { SQLiteDbTypes } from "src/types"

import styled from "styled-components"

const SQLResultsTableWrapper = styled(EuiPanel)`
  // max-width: 800px;
  margin: 2rem auto;
  padding: 0.5rem 2rem 2rem 2rem;
`
const StyledH3 = styled.h3`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
`

const emptyMessage = (
  <EuiEmptyPrompt title={<h3>Nothing here yet</h3>} titleSize="xs" body={`Run your first query to see some results.`} />
)
const noResultsMessage = (
  <EuiEmptyPrompt title={<h3>No Results</h3>} titleSize="xs" body={`That query did not produce any output.`} />
)

const defaultColumns = Object.values(constants.imdbDatabaseColumnsMapping)

type KnownColumn = keyof typeof constants.imdbDatabaseColumnsMapping

type TablePage = {
  index?: number
  size?: number
}
type FormattedRowCell<T> = {
  value: T
  name: string
  idx?: number
}

function constructColumn(col: KnownColumn) {
  const colObj = constants.imdbDatabaseColumnsMapping[col]

  return colObj ? colObj : { field: col as string, name: col as string, truncateText: true }
}

function constructColumnObjects(columns: KnownColumn[]) {
  if (!columns?.length) return []

  return columns.map((col) => constructColumn(col))
}

function constructRowObj<T>(row: T[], cols: typeof defaultColumns) {
  return R.mapObjIndexed(
    (v: FormattedRowCell<T>) => v.value,
    R.indexBy(
      R.prop("name"),
      row.map((v, idx) => ({ value: v, name: cols[idx].name, idx }))
    )
  )
}

function constructRowObjects<T>(rows: T[][], cols: typeof defaultColumns) {
  return rows?.length ? rows.map((row) => constructRowObj(row, cols)) : []
}

interface ISQLResultsTable {
  results?: SQLiteDbTypes.SQLiteQueryExecResult
  pageSize?: number
}
export default function SQLResultsTable({ results, pageSize = 10 }: ISQLResultsTable) {
  // console.log({ results })

  const [pageIndex, setPageIndex] = React.useState(0)
  const cols = React.useMemo(() => constructColumnObjects((results?.columns as KnownColumn[]) || []), [results])
  const rows = React.useMemo(() => constructRowObjects(results?.values || [], cols), [results, cols])

  const onTableChange = ({ page = {} }: { page: TablePage }) => {
    const { index: pageIndex } = page

    if (!R.isNil(pageIndex)) {
      setPageIndex(pageIndex)
    }
  }

  const pageOfItems = rows.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: rows.length,
  }

  const renderNoItemsMessage = () => {
    if (R.isNil(results)) return emptyMessage
    return results?.columns?.length ? null : noResultsMessage
  }

  const noItemsMessage = renderNoItemsMessage()

  const renderRowAmount = () => {
    const totalRows = rows.length

    return (
      <EuiBadge color="hollow">
        {totalRows} total {totalRows === 1 ? "row" : "rows"}
      </EuiBadge>
    )
  }

  return (
    <SQLResultsTableWrapper>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem>
          <StyledH3>Results</StyledH3>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{renderRowAmount()}</EuiFlexItem>
      </EuiFlexGroup>

      <EuiBasicTable
        items={pageOfItems}
        columns={cols}
        pagination={pagination}
        onChange={onTableChange}
        noItemsMessage={noItemsMessage}
      />
    </SQLResultsTableWrapper>
  )
}
