import initialState, { StateShape } from "./initialState"
import { coerceChallengeId } from "src/utils/challenges"
import { CodeEditorTypes } from "src/types"

export default function codeEditorReducer(state = initialState.editor, action: CodeEditorTypes.CodeEditorAction) {
  const challengeId = coerceChallengeId(action.challengeId)
  switch (action.type) {
    case CodeEditorTypes.CodeEditorActionType.SET_CODE_TEXT:
      return {
        ...state,
        codeText: {
          ...state.codeText,
          [challengeId]: action.text,
        },
      }
    case CodeEditorTypes.CodeEditorActionType.RESET_CODE_TEXT:
      return {
        ...state,
        codeText: {
          ...state.codeText,
          [challengeId]: "",
        },
      }
    default:
      return state
  }
}

const setCodeText = (text: string, challengeId: string) => ({
  type: CodeEditorTypes.CodeEditorActionType.SET_CODE_TEXT,
  text,
  challengeId,
})
const resetCodeText = (challengeId: string) => ({
  type: CodeEditorTypes.CodeEditorActionType.RESET_CODE_TEXT,
  challengeId,
})

export const Actions = { setCodeText, resetCodeText }

export const getCodeTextForChallenge = (state: StateShape, challengeId: string): string => {
  return state.editor.codeText[coerceChallengeId(challengeId)]
}
