import * as R from "ramda"

export function isNil(val: any) {
  return R.isNil(val)
}

export function isDefined(value: any) {
  return typeof value !== "undefined" && value !== null
}

export function isValidNumber(value: any): value is number {
  if (typeof value !== "number") return false

  if (Number.isNaN(Number(value))) return false

  return true
}

export const isValidId = (challengeId: any) => isDefined(challengeId) && isValidNumber(challengeId)

type RamdaJsTypes = "Object" | "Number" | "Boolean" | "String" | "Null" | "Array" | "RegExp" | "Function" | "Undefined"
export const isOfType = (value: any, datatype: RamdaJsTypes) => {
  return R.type(value) === datatype
}
export const isObject = (value: any): value is Object => {
  return isOfType(value, "Object")
}
export const isString = (value: any): value is string => {
  return isOfType(value, "String")
}
export const isNumber = (value: any): value is number => {
  return isOfType(value, "Number")
}
export const isBoolean = (value: any): value is boolean => {
  return isOfType(value, "Boolean")
}
export const isNull = (value: any): value is null => {
  return isOfType(value, "Null")
}
export const isArray = (value: any): value is Array<any> => {
  return isOfType(value, "Array")
}
export const isRegExp = (value: any): value is RegExp => {
  return isOfType(value, "RegExp")
}
export const isFunction = (value: any): value is Function => {
  return isOfType(value, "Function")
}
export const isUndefined = (value: any): value is undefined => {
  return isOfType(value, "Undefined")
}
