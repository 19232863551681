module.exports = {
  info: {
    things: {
      stuff: [],
    },
  },
  siteTitle: "sqlchallenges.io",
  siteTitleShort: "sqlchallenges.io",
  subTitle: "Solve fun SQL challenges in the browser.",
  siteTitleAlt: "Solve fun SQL challenges in the browser",
  siteLogo: "",
  siteUrl: "https://sqlchallenges.io",
  pathPrefix: "/",
  siteDescription: "Solve fun SQL challenges in the browser.",
  googleAnalyticsID: "",
  dateFromFormat: "YYY-MM-DD",
  copyright: "Copyright © 2021 Velup LLC",
  themeColor: "#c62828",
  backgroundColor: "#e0e0e0",
}
