import * as R from "ramda"
import { PURGE, REHYDRATE } from "redux-persist"
import { ThunkAction } from "redux-thunk"
import initialState, { StateShape } from "src/models/initialState"
import { coerceChallengeId, coerceTaskId } from "src/utils/challenges"
import { ChallengeTypes } from "src/types"

export default function challengesReducer(state = initialState.challenges, action: ChallengeTypes.ChallengeAction) {
  switch (action.type) {
    case ChallengeTypes.ChallengesActionType.COMPLETE_TASK:
      const taskId = coerceTaskId(action.taskId)
      const currentTask = state.data[action.challengeId].currentTask

      return {
        ...state,
        data: {
          ...state.data,
          [action.challengeId]: {
            ...state.data[action.challengeId],
            tasks: {
              ...state.data[action.challengeId].tasks,
              [taskId]: { completed: true, timestamp: new Date().toISOString() },
            },
            currentTask: currentTask + 1,
          },
        },
      }
    case ChallengeTypes.ChallengesActionType.CLEAR_PROGRESS:
      return initialState.challenges
    case REHYDRATE:
      // console.log("REHYDRATING!!!!")
      return state
    case PURGE:
      // console.log("PURGING!!!!")
      return state
    default:
      return state
  }
}

type CompleteTaskReturnType = ThunkAction<
  ChallengeTypes.ChallengeAction,
  StateShape,
  void,
  ChallengeTypes.ChallengeAction
>
const completeTask = (challengeId: string, taskId: number): CompleteTaskReturnType => {
  return (dispatch) => {
    return dispatch({
      type: ChallengeTypes.ChallengesActionType.COMPLETE_TASK,
      challengeId,
      taskId,
    })
  }
}

type ClearProgressReturnType = ThunkAction<
  ChallengeTypes.ChallengeAction,
  StateShape,
  void,
  ChallengeTypes.ChallengeAction
>
const clearProgress = (): ClearProgressReturnType => {
  return (dispatch) => {
    // dispatch({
    //   type: PURGE,
    //   key: "root",
    //   result: () => null,
    // })

    dispatch({
      type: PURGE,
      key: "challenges",
      result: () => null,
    })

    return dispatch({
      type: ChallengeTypes.ChallengesActionType.CLEAR_PROGRESS,
    })
  }
}

export const Actions = { completeTask, clearProgress }

export const selectTaskStateForChallenge = (state: StateShape, challengeId: string) => {
  return state.challenges.data[coerceChallengeId(challengeId)]
}

export const selectCompletedTasksForChallenge = (state: StateShape, challengeId: string) => {
  return selectTaskStateForChallenge(state, challengeId)?.tasks ?? []
}

export const selectCompletedTasks = (state: StateShape) => {
  return R.mapObjIndexed(
    (v: Record<string, any>) => v.completed,
    R.indexBy(
      R.prop("challengeId"),
      R.map(
        (challengeId) => ({ challengeId, completed: selectCompletedTasksForChallenge(state, challengeId) }),
        R.keys(state.challenges.data)
      )
    )
  )
}
