import React from "react"
import {
  EuiPanel,
  EuiCodeBlock,
  EuiMarkdownFormat,
  // EuiSpacer,
  EuiSteps,
  EuiText,
} from "@elastic/eui"
import { determineTaskStatus } from "src/utils/challenges"
import styled from "styled-components"

import { ChallengeTypes } from "src/types"

const TasksPanelWrapper = styled.div`
  margin-left: 2rem;
  max-width: 500px;
`
const StyledEuiPanel = styled(EuiPanel)`
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
`

interface ITaskStep {
  task: ChallengeTypes.Task
}
const TaskStep: React.FC<ITaskStep> = ({ task }) => {
  return (
    <EuiText>
      <EuiMarkdownFormat>{task.instructions}</EuiMarkdownFormat>
      <>{task.codeSample ? <EuiCodeBlock language="sql">{task.codeSample}</EuiCodeBlock> : null}</>
    </EuiText>
  )
}

const createTaskSteps = (
  tasks: ChallengeTypes.Task[],
  taskState: ChallengeTypes.TaskCompletion,
  isProcessing?: boolean,
  failed?: boolean
) => {
  return tasks.map((task, idx) => ({
    title: `Task ${idx + 1}`,
    status: determineTaskStatus(task, taskState, idx, isProcessing, failed),
    children: (
      <>
        <TaskStep task={task} />
      </>
    ),
  }))
}

interface ITasksPanel {
  tasks: ChallengeTypes.Task[]
  taskState: ChallengeTypes.TaskCompletion
  isProcessing?: boolean
  failed?: boolean
}
export default function TasksPanel({ tasks, taskState, isProcessing, failed }: ITasksPanel) {
  const steps = createTaskSteps(tasks, taskState, isProcessing, failed)

  return (
    <TasksPanelWrapper>
      <StyledEuiPanel>
        <EuiSteps titleSize="xs" steps={steps} />
      </StyledEuiPanel>
    </TasksPanelWrapper>
  )
}
