import React from "react"
import { EuiBasicTable } from "@elastic/eui"
import styled from "styled-components"

const CountriesPopulationTableWrapper = styled.div`
  max-width: 600px;
  margin-top: 1.5rem;

  & table {
    border: solid 1px #d3dae6;
    border-radius: 4px;

    & thead {
      background: #e3eaf6;
    }
  }
`

const items = [
  { Syntax: `%d`, Description: `day of month: 00` },
  { Syntax: `%f`, Description: `fractional seconds: SS.SSS` },
  { Syntax: `%H`, Description: `hour: 00-24` },
  { Syntax: `%j`, Description: `day of year: 001-366` },
  { Syntax: `%J`, Description: `Julian day number` },
  { Syntax: `%m`, Description: `month: 01-12` },
  { Syntax: `%M`, Description: `minute: 00-59` },
  { Syntax: `%s`, Description: `seconds since 1970-01-01` },
  { Syntax: `%S`, Description: `seconds: 00-59` },
  { Syntax: `%w`, Description: `day of week 0-6 with Sunday==0` },
  { Syntax: `%W`, Description: `week of year: 00-53` },
  { Syntax: `%Y`, Description: `year: 0000-9999` },
  { Syntax: `%%`, Description: `%` },
]

const columns = [
  {
    field: `Syntax`,
    name: `syntax`,
    truncateText: false,
  },
  {
    field: `Description`,
    name: `description`,
    truncateText: false,
  },
]

export default function DatetimeFormatTable() {
  return (
    <CountriesPopulationTableWrapper>
      <>
        <EuiBasicTable
          // textOnly={true}
          tableLayout="fixed"
          responsive={false}
          // @ts-ignore
          items={items}
          columns={columns}
        />
      </>
    </CountriesPopulationTableWrapper>
  )
}
