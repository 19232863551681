import React from "react"
import * as R from "ramda"
import { EuiInMemoryTable, EuiTabs, EuiTab, EuiSpacer } from "@elastic/eui"
import * as constants from "src/utils/constants"
import styled from "styled-components"

import { SQLiteDbTypes } from "src/types"

const columns = [
  {
    field: `name`,
    name: `Column Name`,
    truncateText: false,
    width: "20%",
  },
  {
    field: `dataType`,
    name: `Data Type`,
    truncateText: false,
    width: "20%",
  },
  {
    field: `description`,
    name: `Description`,
    truncateText: true,
    width: "60%",
  },
]

const itemsMapping: Record<SQLiteDbTypes.DatabaseName, Record<string, any>> = {
  moviesDb: constants.imdbDatabaseColumnsDescription,
  countriesDb: constants.countriesDatabaseColumnsDescription,
  olympicsDb: constants.olympicsDatabaseColumnsDescription,
  db: constants.imdbDatabaseColumnsDescription,
  blogDb: constants.blogDatabaseColumnDescription,
  usersDb: constants.usersDatabaseColumnDescription,
}

const DataDescriptionTableWrapper = styled.div``

interface IDataDescriptionTable {
  databaseName: SQLiteDbTypes.DatabaseName
}
export default function DataDescriptionTable({ databaseName = "moviesDb" }: IDataDescriptionTable) {
  const databaseItems = itemsMapping[databaseName]
  const tables = constants.databaseToTablesMapping[databaseName]
  const [currentTable, setCurrentTable] = React.useState(tables[0])
  const items = R.values(databaseItems[currentTable])

  React.useEffect(() => {
    setCurrentTable(tables[0])
  }, [databaseName])

  const tabs = () => {
    return tables.map((table: string) => (
      <EuiTab onClick={() => setCurrentTable(table)} isSelected={table === currentTable} key={table}>
        {table}
      </EuiTab>
    ))
  }

  const renderTabs = () => {
    if (tables.length) {
      return <EuiTabs size="l">{tabs()}</EuiTabs>
    }

    return null
  }

  return (
    <DataDescriptionTableWrapper>
      {renderTabs()}
      <EuiSpacer size="xl" />
      {currentTable ? (
        <EuiInMemoryTable
          items={items}
          columns={columns}
          pagination={false}
          // something
        />
      ) : (
        <EuiInMemoryTable
          items={items}
          columns={columns}
          pagination={false}
          // something
        />
      )}
    </DataDescriptionTableWrapper>
  )
}
