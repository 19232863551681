import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import localForage from "localforage"

const challengesPersistConfig = {
  key: "challenges",
  storage: localForage,
}

import challengesReducer from "./challenges"
import codeEditorReducer from "./codeEditor"
import uiReducer from "./ui"

const rootReducer = combineReducers({
  challenges: persistReducer(challengesPersistConfig, challengesReducer),
  editor: codeEditorReducer,
  ui: uiReducer,
})

export default rootReducer
