import { ThunkAction } from "redux-thunk"

export type ToastID = string

export interface StyledComponent {
  className?: string
}

export interface IIconProps {
  size?: number
  circle?: boolean
  color?: string
  fill?: any
  stroke?: string
  strokeWidth?: number
  strokeLinecap?: "square" | "butt" | "round" | "inherit"
  strokeMiterlimit?: number
  strokeLinejoin?: "miter" | "round" | "inherit" | "bevel"
  style?: Record<string, any>
  className?: string
  fillBackground?: any
  fillText?: any
  fillRule?: "nonzero" | "evenodd" | "inherit"
  alt?: string
  [key: string]: any
}

export enum ToastType {
  DANGER = "danger",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export interface Toast {
  id: ToastID
  title: string
  contents: string
  type: ToastType
}

export interface UiState {
  toastList: Toast[]
}

export enum UiActionType {
  ADD_UI_TOAST = "@@ui/ADD_UI_TOAST",
  REMOVE_UI_TOAST = "@@ui/REMOVE_UI_TOAST",
  CLEAR_UI_STATE = "@@ui/CLEAR_UI_STATE",
}

export interface IClearUiState {
  type: UiActionType.CLEAR_UI_STATE
}
export interface IAddUiToast {
  type: UiActionType.ADD_UI_TOAST
  toast: Toast
}
export interface IRemoveUiToast {
  type: UiActionType.REMOVE_UI_TOAST
  toast: Toast
}
export type UiToastAction = IAddUiToast | IRemoveUiToast
export type UiAction = IClearUiState | UiToastAction

export type UiToastThunkAction = ThunkAction<UiToastAction | null, { ui: UiState }, void, UiToastAction>
