export const databaseToTablesMapping = {
  moviesDb: ["movies", "directors"],
  countriesDb: ["countries_of_the_world"],
  olympicsDb: ["winter_olympics"],
  db: ["movies"],
  usersDb: ["users", "profiles", "tweets", "favorites", "followers_to_following"],
  blogDb: ["users", "profiles", "posts", "comments"],
}

export const databaseNameToDisplayNameMapping = {
  moviesDb: "IMDB Movies",
  countriesDb: "Countries of the World",
  olympicsDb: "Winter Olympics",
  db: "Fresh",
  usersDb: "Users",
  blogDb: "Blog",
}

export const imdbDatabaseColumnsMapping = {
  id: {
    field: `id`,
    name: `id`,
    truncateText: true,
  },
  original_title: {
    field: `original_title`,
    name: `original_title`,
    truncateText: true,
  },
  title: {
    field: `title`,
    name: `title`,
    truncateText: true,
  },
  budget: {
    field: `budget`,
    name: `budget`,
    truncateText: false,
    // render: (v: string | number) => <>{formatCurrency(v)}</>,
  },
  popularity: {
    field: `popularity`,
    name: `popularity`,
    truncateText: false,
  },
  release_date: {
    field: `release_date`,
    name: `release_date`,
    truncateText: true,
  },
  revenue: {
    field: `revenue`,
    name: `revenue`,
    truncateText: true,
    // render: (v: string | number) => <>{formatCurrency(v)}</>,
  },
  overview: {
    field: `overview`,
    name: `overview`,
    truncateText: true,
  },
  vote_average: {
    field: `vote_average`,
    name: `vote_average`,
    truncateText: true,
  },
  vote_count: {
    field: `vote_count`,
    name: `vote_count`,
    truncateText: true,
  },
  tagline: {
    field: `tagline`,
    name: `tagline`,
    truncateText: true,
  },
  uid: {
    field: `uid`,
    name: `uid`,
    truncateText: true,
  },
  director_id: {
    field: `director_id`,
    name: `director_id`,
    truncateText: true,
  },
}

export const imdbDatabaseColumnsDescription = {
  movies: {
    id: {
      field: `id`,
      name: `id`,
      dataType: `INTEGER`,
      description: `An arbitrary id of the movie in the IMDB database.`,
      // name: `ID`,
      truncateText: true,
    },
    original_title: {
      field: `original_title`,
      name: `original_title`,
      dataType: `VARCHAR`,
      description: `The original title of the movie, potentially in the movie's original language.`,
      // name: `Original Title`,
      truncateText: true,
    },
    title: {
      field: `title`,
      name: `title`,
      dataType: `TEXT`,
      description: `The title of the movie when it premiered in the United States.`,
      // name: `Title`,
      truncateText: true,
    },
    budget: {
      field: `budget`,
      name: `budget`,
      dataType: `INTEGER`,
      description: `Budget of the movie in US dollars.`,
      // name: `Budget`,
      truncateText: false,
      // render: (v: string | number) => <>{formatCurrency(v)}</>,
    },
    popularity: {
      field: `popularity`,
      name: `popularity`,
      dataType: `INTEGER`,
      description: `The Popularity Score assigned by TMDB.`,
      // name: `Popularity`,
      truncateText: false,
    },
    release_date: {
      field: `release_date`,
      name: `release_date`,
      dataType: `TEXT`,
      description: `The year, month, and date in which the movie was released (1916 - 2017).`,
      truncateText: true,
    },
    revenue: {
      field: `revenue`,
      name: `revenue`,
      dataType: `INTEGER`,
      description: `World-wide revenue of the movie in US dollars`,
      truncateText: false,
      // render: (v: string | number) => <>{formatCurrency(v)}</>,
    },
    overview: {
      field: `overview`,
      name: `overview`,
      dataType: `TEXT`,
      description: `A description of the plot and general overview of the movie.`,
      truncateText: true,
    },
    vote_average: {
      field: `vote_average`,
      name: `vote_average`,
      dataType: `REAL`,
      description: `The mean score of all votes cast for this movie.`,

      truncateText: false,
    },
    vote_count: {
      field: `vote_count`,
      name: `vote_count`,
      dataType: `INTEGER`,
      description: `The total number of votes cast for this movie.`,
      truncateText: false,
    },
    tagline: {
      field: `tagline`,
      name: `tagline`,
      dataType: `TEXT`,
      description: `A short blurb about the movie meant to hook the audience.`,
      truncateText: true,
    },
    uid: {
      field: `uid`,
      name: `uid`,
      dataType: `INTEGER`,
      description: `The IMDB ID of the movie.`,
      truncateText: false,
    },
    director_id: {
      field: `director_id`,
      name: `director_id`,
      dataType: `INTEGER`,
      description: `The id of the movie's primary director in the IMDB database.`,
      truncateText: false,
    },
  },
  directors: {
    name: {
      field: `name`,
      name: `name`,
      dataType: `TEXT`,
      description: `The name of the director.`,
      truncateText: true,
    },
    id: {
      field: `id`,
      name: `id`,
      dataType: `INTEGER`,
      description: `The id of the director in the IMDB database.`,
      truncateText: false,
    },
    gender: {
      field: `gender`,
      name: `gender`,
      dataType: `INTEGER`,
      description: `An integer representing the gender of the director.`,
      truncateText: false,
    },
  },
}

export const countriesDatabaseColumnsMapping = {
  country_name: {
    field: `country_name`,
    name: `country_name`,
    truncateText: true,
  },
  region: {
    field: `region`,
    name: `region`,
    truncateText: true,
  },
  birth_rate: {
    field: `birth_rate`,
    name: `birth_rate`,
    truncateText: true,
  },
  death_rate: {
    field: `death_rate`,
    name: `death_rate`,
    truncateText: false,
  },
  gdp: {
    field: `gdp`,
    name: `gdp`,
    truncateText: true,
  },
  land_area: {
    field: `land_area`,
    name: `land_area`,
    truncateText: false,
  },
  life_expectancy: {
    field: `life_expectancy`,
    name: `life_expectancy`,
    truncateText: true,
  },
  infant_mortality: {
    field: `infant_mortality`,
    name: `infant_mortality`,
    truncateText: true,
  },
  population: {
    field: `population`,
    name: `population`,
    truncateText: true,
  },
  year: {
    field: `year`,
    name: `year`,
    truncateText: false,
  },
}

export const countriesDatabaseColumnsDescription = {
  countries_of_the_world: {
    country: {
      field: `country_name`,
      name: `country_name`,
      truncateText: true,
      dataType: `TEXT`,
      description: `The name of the country.`,
    },
    region: {
      field: `region`,
      name: `region`,
      truncateText: true,
      dataType: `TEXT`,
      description: `The region of the world that this country is located in.`,
    },
    birth_rate: {
      field: `birth_rate`,
      name: `birth_rate`,
      truncateText: true,
      dataType: `REAL`,
      description: `Indicates the number of births occurring during the year per 1,000 population.`,
    },
    death_rate: {
      field: `death_rate`,
      name: `death_rate`,
      truncateText: false,
      dataType: `REAL`,
      description: `Indicates the number of deaths occurring during the year per 1,000 population.`,
    },
    gdp: {
      field: `gdp`,
      name: `gdp`,
      truncateText: true,
      dataType: `REAL`,
      description: `Gross domestic in current US dollars.`,
    },
    land_area: {
      field: `land_area`,
      name: `land_area`,
      truncateText: false,
      dataType: `REAL`,
      description: `The total area of the country's landmass in kilometers.`,
    },
    life_expectancy: {
      field: `life_expectancy`,
      name: `life_expectancy`,
      truncateText: true,
      dataType: `REAL`,
      description: `The expected number of years an individual will live at birth.`,
    },
    infant_mortality: {
      field: `infant_mortality`,
      name: `infant_mortality`,
      truncateText: true,
      dataType: `REAL`,
      description: `The number of infant deaths per 1000 births.`,
    },
    population: {
      field: `population`,
      name: `population`,
      truncateText: true,
      dataType: `REAL`,
      description: `The total population of the country in the given year`,
    },
    year: {
      field: `year`,
      name: `year`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The year that the data in this row refers to.`,
    },
  },
}

export const olympicsDatabaseColumnsMapping = {
  id: {
    field: `id`,
    name: `id`,
    truncateText: true,
  },
  name: {
    field: `name`,
    name: `name`,
    truncateText: true,
  },
  gender: {
    field: `gender`,
    name: `gender`,
    truncateText: false,
  },
  age: {
    field: `age`,
    name: `age`,
    truncateText: false,
  },
  height: {
    field: `height`,
    name: `height`,
    truncateText: true,
  },
  weight: {
    field: `weight`,
    name: `weight`,
    truncateText: false,
  },
  team: {
    field: `team`,
    name: `team`,
    truncateText: true,
  },
  NOC: {
    field: `NOC`,
    name: `NOC`,
    truncateText: true,
  },
  games: {
    field: `games`,
    name: `games`,
    truncateText: true,
  },
  year: {
    field: `year`,
    name: `year`,
    truncateText: false,
  },
  season: {
    field: `season`,
    name: `season`,
    truncateText: false,
  },
  city: {
    field: `city`,
    name: `city`,
    truncateText: false,
  },
  sport: {
    field: `sport`,
    name: `sport`,
    truncateText: false,
  },
  event: {
    field: `event`,
    name: `event`,
    truncateText: false,
  },
  medal: {
    field: `medal`,
    name: `medal`,
    truncateText: false,
  },
}

export const olympicsDatabaseColumnsDescription = {
  winter_olympics: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: true,
      dataType: `INTEGER`,
      description: `The unique id of the row.`,
    },
    name: {
      field: `name`,
      name: `name`,
      truncateText: true,
      dataType: `TEXT`,
      description: `The name of the Winter Olympics medal winner.`,
    },
    gender: {
      field: `gender`,
      name: `gender`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The gender category that the participant competed under.`,
    },
    age: {
      field: `age`,
      name: `age`,
      truncateText: false,
      dataType: `REAL`,
      description: `The age of the participant.`,
    },
    height: {
      field: `height`,
      name: `height`,
      truncateText: true,
      dataType: `REAL`,
      description: `The height of the participant in centimeters.`,
    },
    weight: {
      field: `weight`,
      name: `weight`,
      truncateText: false,
      dataType: `REAL`,
      description: `The weight of the participant in kilograms.`,
    },
    team: {
      field: `team`,
      name: `team`,
      truncateText: true,
      dataType: `TEXT`,
      description: `The team that the participant competed for.`,
    },
    NOC: {
      field: `NOC`,
      name: `NOC`,
      truncateText: true,
      dataType: `TEXT`,
      description: `The National Olympic Committee 3-letter code for the participant's team.`,
    },
    games: {
      field: `games`,
      name: `games`,
      truncateText: true,
      dataType: `TEXT`,
      description: `The Olympics games that the participant competed in.`,
    },
    year: {
      field: `year`,
      name: `year`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The year that the data in this row refers to.`,
    },
    season: {
      field: `season`,
      name: `season`,
      truncateText: false,
      dataType: `TEXT`,
      description: `Either summer or winter olympics.`,
    },
    city: {
      field: `city`,
      name: `city`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The city that these Olympics games were located in.`,
    },
    sport: {
      field: `sport`,
      name: `sport`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The sport that this participant competed in.`,
    },
    event: {
      field: `event`,
      name: `event`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The event that this participant competed in.`,
    },
    medal: {
      field: `medal`,
      name: `medal`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The medal that this participant earned in their event.`,
    },
  },
}

export const blogDatabaseColumnsMapping = {
  id: {
    field: `id`,
    name: `id`,
    truncateText: false,
  },
  username: {
    field: `username`,
    name: `username`,
    truncateText: true,
  },
  email: {
    field: `email`,
    name: `email`,
    truncateText: true,
  },
  password: {
    field: `password`,
    name: `password`,
    truncateText: true,
  },
  is_admin: {
    field: `is_admin`,
    name: `is_admin`,
    truncateText: true,
  },
  first_name: {
    field: `first_name`,
    name: `first_name`,
    truncateText: true,
  },
  last_name: {
    field: `last_name`,
    name: `last_name`,
    truncateText: true,
  },
  bio: {
    field: `bio`,
    name: `bio`,
    truncateText: true,
  },
  title: {
    field: `title`,
    name: `title`,
    truncateText: true,
  },
  content: {
    field: `content`,
    name: `content`,
    truncateText: true,
  },
  published: {
    field: `published`,
    name: `published`,
    truncateText: false,
  },
  comment: {
    field: `comment`,
    name: `comment`,
    truncateText: true,
  },
  post_id: {
    field: `post_id`,
    name: `post_id`,
    truncateText: false,
  },
  user_id: {
    field: `user_id`,
    name: `user_id`,
    truncateText: false,
  },
}

export const blogDatabaseColumnDescription = {
  users: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The id of this user.`,
    },
    username: {
      field: `username`,
      name: `username`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The username of this user.`,
    },
    email: {
      field: `email`,
      name: `email`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The email of this user.`,
    },
    password: {
      field: `password`,
      name: `password`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The unencrypted, plain-text password of this user.`,
    },
    is_admin: {
      field: `is_admin`,
      name: `is_admin`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A number representing whether or not this user is an admin of the site.`,
    },
  },
  profiles: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The id of this profile.`,
    },
    first_name: {
      field: `first_name`,
      name: `first_name`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The first name for the user of this profile.`,
    },
    last_name: {
      field: `last_name`,
      name: `last_name`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The last name for the user of this profile.`,
    },
    bio: {
      field: `bio`,
      name: `bio`,
      truncateText: false,
      dataType: `TEXT`,
      description: `A short biography written about the user of this profile.`,
    },
    user_id: {
      field: `user_id`,
      name: `user_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key relating the profile to the user in the users table.`,
    },
  },
  posts: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The id of this post.`,
    },
    title: {
      field: `title`,
      name: `title`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The title of this post.`,
    },
    content: {
      field: `content`,
      name: `content`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The actual content of the post.`,
    },
    published: {
      field: `published`,
      name: `published`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A number representing whether or not this post is published.`,
    },
    user_id: {
      field: `user_id`,
      name: `user_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the author of this post in the users table.`,
    },
  },
  comments: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The id of this comment.`,
    },
    comment: {
      field: `comment`,
      name: `comment`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The actual comment written by the user.`,
    },
    post_id: {
      field: `post_id`,
      name: `post_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the post this comment was written for.`,
    },
    user_id: {
      field: `user_id`,
      name: `user_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the author of this comment in the users table.`,
    },
  },
}

export const usersDatabaseColumnDescription = {
  users: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The id of this user.`,
    },
    username: {
      field: `username`,
      name: `username`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The username of this user.`,
    },
    email: {
      field: `email`,
      name: `email`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The email of this user.`,
    },
    password: {
      field: `password`,
      name: `password`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The unencrypted, plain-text password of this user.`,
    },
    is_admin: {
      field: `is_admin`,
      name: `is_admin`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A number representing whether or not this user is an admin of the site.`,
    },
  },
  profiles: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The id of this profile.`,
    },
    first_name: {
      field: `first_name`,
      name: `first_name`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The first name for the user of this profile.`,
    },
    last_name: {
      field: `last_name`,
      name: `last_name`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The last name for the user of this profile.`,
    },
    bio: {
      field: `bio`,
      name: `bio`,
      truncateText: false,
      dataType: `TEXT`,
      description: `A short biography written about the user of this profile.`,
    },
    user_id: {
      field: `user_id`,
      name: `user_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key relating the profile to the user in the users table.`,
    },
  },
  tweets: {
    id: {
      field: `id`,
      name: `id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `The id of this tweet.`,
    },
    tweet: {
      field: `tweet`,
      name: `tweet`,
      truncateText: false,
      dataType: `TEXT`,
      description: `The content of this tweet.`,
    },
    timestamp: {
      field: `content`,
      name: `content`,
      truncateText: false,
      dataType: `TEXT`,
      description: `A timestamp representing the datetime that the tweet was posted.`,
    },
    user_id: {
      field: `user_id`,
      name: `user_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the author of this tweet in the users table.`,
    },
  },
  favorites: {
    tweet_id: {
      field: `tweet_id`,
      name: `tweet_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the id of the tweet being favorited.`,
    },
    user_id: {
      field: `user_id`,
      name: `user_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the user who favorited the tweet.`,
    },
  },
  followers_to_following: {
    follower_id: {
      field: `follower_id`,
      name: `follower_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the id of the user who is doing the following.`,
    },
    following_id: {
      field: `following_id`,
      name: `following_id`,
      truncateText: false,
      dataType: `INTEGER`,
      description: `A foreign key referencing the user who is being followed.`,
    },
  },
}
