// import React from "react"
// import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui"
// import styled from "styled-components"

// const SQLEditorWrapper = styled.div``

// interface ISQLEditor {
//   codeText: string
//   setCodeText: (t: string) => void
//   handleOnExecute: () => void
//   isProcessing?: boolean
//   height?: string
//   className?: string
// }
// export default function SQLEditor({
//   codeText,
//   setCodeText,
//   handleOnExecute,
//   isProcessing,
//   className = "",
//   height = "300px",
// }: ISQLEditor) {
//   const handleOnCodeTextChange = (value: string) => {
//     setCodeText(value)
//   }

//   return (
//     <SQLEditorWrapper className={className}>
//       {/* <EuiCodeEditor
//         mode="sql"
//         theme="tomorrow"
//         width="100%"
//         height={height}
//         value={codeText}
//         onChange={handleOnCodeTextChange}
//         setOptions={{
//           fontSize: "14px",
//           enableBasicAutocompletion: true,
//           // enableSnippets: true,
//           enableLiveAutocompletion: true,
//         }}
//         onBlur={() => {}}
//         aria-label="Code Editor"
//       /> */}
//       <EuiSpacer size="l" />
//       <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false} wrap>
//         <EuiFlexItem grow={false}>
//           <EuiButton
//             isLoading={isProcessing}
//             isDisabled={!codeText || isProcessing}
//             fill
//             onClick={() => handleOnExecute()}
//           >
//             Execute Query
//           </EuiButton>
//         </EuiFlexItem>
//       </EuiFlexGroup>
//     </SQLEditorWrapper>
//   )
// }

// import React from "react"
// import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiCodeEditor } from "@elastic/eui"
// import styled from "styled-components"
// // import "brace/theme/github"
// import "brace/theme/tomorrow"
// // import 'brace/mode/javascript';
// import "brace/mode/sql"
// // import 'brace/snippets/javascript';
// import "brace/snippets/sql"
// import "brace/ext/language_tools"

// const SQLEditorWrapper = styled.div``

// interface ISQLEditor {
//   codeText: string
//   setCodeText: (t: string) => void
//   handleOnExecute: () => void
//   isProcessing?: boolean
//   height?: string
//   className?: string
// }
// export default function SQLEditor({
//   codeText,
//   setCodeText,
//   handleOnExecute,
//   isProcessing,
//   className = "",
//   height = "300px",
// }: ISQLEditor) {
//   const handleOnCodeTextChange = (value: string) => {
//     setCodeText(value)
//   }

//   return (
//     <SQLEditorWrapper className={className}>
//       <EuiCodeEditor
//         mode="sql"
//         theme="tomorrow"
//         width="100%"
//         height={height}
//         value={codeText}
//         onChange={handleOnCodeTextChange}
//         setOptions={{
//           fontSize: "14px",
//           enableBasicAutocompletion: true,
//           // enableSnippets: true,
//           enableLiveAutocompletion: true,
//         }}
//         onBlur={() => {}}
//         aria-label="Code Editor"
//       />
//       <EuiSpacer size="l" />
//       <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false} wrap>
//         <EuiFlexItem grow={false}>
//           <EuiButton
//             isLoading={isProcessing}
//             isDisabled={!codeText || isProcessing}
//             fill
//             onClick={() => handleOnExecute()}
//           >
//             Execute Query
//           </EuiButton>
//         </EuiFlexItem>
//       </EuiFlexGroup>
//     </SQLEditorWrapper>
//   )
// }

import React from "react"
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui"
import styled from "styled-components"

// const ClientSideOnlyEditor = React.lazy(() => import("../IsomorphicEdtor/IsomorphicEditor"))
const IsomorphicCodeEditor = React.lazy(() => import("../IsomorphicEditor/IsomorphicEditor"))

const SQLEditorWrapper = styled.div``

interface ISQLEditor {
  codeText: string
  setCodeText: (t: string) => void
  handleOnExecute: () => void
  isProcessing?: boolean
  height?: string
  className?: string
}
export default function SQLEditor({
  codeText,
  setCodeText,
  handleOnExecute,
  isProcessing,
  className = "",
  height = "300px",
}: ISQLEditor) {
  const isSSR = typeof window === "undefined"

  const handleOnCodeTextChange = (value: string) => {
    setCodeText(value)
  }

  return (
    <SQLEditorWrapper className={className}>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <IsomorphicCodeEditor
            mode="sql"
            theme="tomorrow"
            width="100%"
            height={height}
            value={codeText}
            onChange={handleOnCodeTextChange}
            setOptions={{
              fontSize: "14px",
              enableBasicAutocompletion: true,
              // enableSnippets: true,
              enableLiveAutocompletion: true,
            }}
            onBlur={() => {}}
            aria-label="Code Editor"
          />
        </React.Suspense>
      )}
      {/* <EuiCodeEditor
        mode="sql"
        theme="tomorrow"
        width="100%"
        height={height}
        value={codeText}
        onChange={handleOnCodeTextChange}
        setOptions={{
          fontSize: "14px",
          enableBasicAutocompletion: true,
          // enableSnippets: true,
          enableLiveAutocompletion: true,
        }}
        onBlur={() => {}}
        aria-label="Code Editor"
      /> */}
      <EuiSpacer size="l" />
      <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false} wrap>
        <EuiFlexItem grow={false}>
          <EuiButton
            isLoading={isProcessing}
            isDisabled={!codeText || isProcessing}
            fill
            onClick={() => handleOnExecute()}
          >
            Execute Query
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </SQLEditorWrapper>
  )
}
