import React from "react"
import { EuiBasicTable } from "@elastic/eui"
import { formatPopulation } from "src/utils/format"
import styled from "styled-components"

const CountriesPopulationTableWrapper = styled.div`
  max-width: 400px;
  margin-top: 1.5rem;

  & table {
    border: solid 1px #d3dae6;
    border-radius: 4px;

    & thead {
      background: #e3eaf6;
    }
  }
`

const items = [
  { Country: `Afghanistan`, Population: 36296400 },
  { Country: `Albania`, Population: 2873457 },
  { Country: `Algeria`, Population: 41389198 },
  { Country: `American Samoa`, Population: 55620 },
]

const columns = [
  {
    field: `Country`,
    name: `Country`,
    truncateText: false,
  },
  {
    field: `Population`,
    name: `Population`,
    render: (pop: number) => <>{formatPopulation(pop)} </>,
    truncateText: false,
  },
]

export default function CountriesPopulationTable() {
  return (
    <CountriesPopulationTableWrapper>
      <>
        <EuiBasicTable
          // textOnly={true}
          tableLayout="fixed"
          responsive={false}
          // @ts-ignore
          items={items}
          columns={columns}
        />
      </>
    </CountriesPopulationTableWrapper>
  )
}
