import React from "react"
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle, EuiSpacer } from "@elastic/eui"
import { EuiCustomLink } from "src/components"
import styled from "styled-components"
import { groupChallengeByCategory } from "src/utils/challenges"
import { ChallengeTypes } from "src/types"

const ChallengeListingWrapper = styled.div`
  & .challenge-number {
    width: 50px;
  }

  & p {
    margin-bottom: 0;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
  }
`

interface IChallengeListing {
  edges: Array<{ node: ChallengeTypes.ChallengeNodeFull }>
}
export default function ChallengeListing({ edges }: IChallengeListing) {
  const challenges = edges.map(({ node }) => node)

  const groupedChallenges = groupChallengeByCategory(challenges)

  const renderChallenge = (challenge: ChallengeTypes.ChallengeNodeFull) => (
    <React.Fragment key={challenge.frontmatter.id}>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <div className="challenge-number">
            <EuiTitle>
              <h4>{challenge.frontmatter.challengeId}</h4>
            </EuiTitle>
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <EuiCustomLink to={`/challenges${challenge.fields.slug}/`}>
              <p>{challenge.frontmatter.title}</p>
            </EuiCustomLink>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )

  return (
    <ChallengeListingWrapper>
      <EuiText>
        <h3>Intro</h3>
      </EuiText>
      <EuiSpacer size="s" />
      {groupedChallenges.intro.map((challenge) => renderChallenge(challenge))}
      <EuiSpacer size="m" />
      <EuiText>
        <h3>Intermediate</h3>
      </EuiText>
      <EuiSpacer size="s" />
      {groupedChallenges.intermediate.map((challenge) => renderChallenge(challenge))}
      <EuiSpacer size="s" />
    </ChallengeListingWrapper>
  )
}
