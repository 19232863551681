import React from "react"
import * as R from "ramda"
import { SQLEditor, SQLResultsTable, TasksPanel } from "src/components"
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiLoadingSpinner,
  EuiHealth,
  EuiPageBody,
  EuiPageContent,
} from "@elastic/eui"
import { noop } from "src/utils/misc"

import { ChallengeTypes, SQLiteDbTypes } from "src/types"

import styled from "styled-components"

const StyledEuiPageBody = styled(EuiPageBody)`
  flex-direction: row;

  & .refresh {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

interface IEditorTasksAndResultsTable extends Omit<SQLiteDbTypes.UseSqliteDatabaseState, SQLiteDbTypes.DatabaseName> {
  codeText: string
  tasks: ChallengeTypes.Task[]
  taskState: ChallengeTypes.TaskCompletion
  completeTask: (taskId: number) => void
  handleOnCodeEditorTextChange: (text: string) => void
  handleOnExecute: () => void
  failed?: boolean
  includeRefresh?: boolean
  refreshDb: () => void
}

export default function EditorTasksAndResultsTable({
  initializing,
  codeText,
  tasks,
  taskState,
  failed,
  // completeTask,
  handleOnCodeEditorTextChange,
  handleOnExecute,
  isProcessing,
  includeRefresh = true,
  refreshDb,
  err,
  results,
}: IEditorTasksAndResultsTable) {
  const renderStatus = () => {
    if (isProcessing) return <EuiHealth color="primary">Querying...</EuiHealth>
    if (err) return <EuiHealth color="danger">{err.toString()}</EuiHealth>
    return <EuiHealth color="success">Good to go</EuiHealth>
  }

  const coerceResults = () => {
    if (R.isNil(results)) return undefined

    if (results?.[0]?.columns?.length) return results[0]

    return { values: [], columns: [] }
  }

  const coercedResults = coerceResults()

  return (
    <>
      <StyledEuiPageBody>
        <EuiPageContent>
          {initializing ? (
            <EuiLoadingSpinner size="xl" />
          ) : (
            <>
              {/* {renderStatus()} */}
              <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                <EuiFlexItem>{renderStatus()}</EuiFlexItem>
                {includeRefresh ? (
                  <EuiFlexItem>
                    <span className="refresh">
                      Refresh DB
                      <EuiButtonIcon
                        aria-label="refresh button"
                        iconType="refresh"
                        onClick={() => (R.isNil(refreshDb) ? noop() : refreshDb())}
                      />
                    </span>
                  </EuiFlexItem>
                ) : null}
              </EuiFlexGroup>
              <SQLEditor
                codeText={codeText}
                setCodeText={handleOnCodeEditorTextChange}
                handleOnExecute={handleOnExecute}
                isProcessing={isProcessing}
              />
            </>
          )}
        </EuiPageContent>

        <TasksPanel tasks={tasks} taskState={taskState} failed={failed} isProcessing={isProcessing} />
      </StyledEuiPageBody>
      {isProcessing ? <EuiLoadingSpinner size="xl" /> : <SQLResultsTable results={coercedResults} />}
    </>
  )
}
