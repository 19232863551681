import * as R from "ramda"
import sqlTasks from "src/data/sqlTasks"
import { UiTypes, CodeEditorTypes, ChallengeTypes } from "src/types"

export interface StateShape {
  challenges: ChallengeTypes.ChallengeState
  ui: UiTypes.UiState
  editor: CodeEditorTypes.CodeEditorState
}

const challengesData = R.mapObjIndexed(
  () => ({
    tasks: {
      "0": { completed: false },
    },
    currentTask: 0,
  }),
  R.indexBy((challengeId) => challengeId, R.keys(sqlTasks))
)

const initialState: StateShape = {
  challenges: {
    data: {
      ...challengesData,
      "0.0": {
        tasks: {
          "0": { completed: true, timestamp: new Date().toISOString() },
        },
        currentTask: 1,
      },
    },
  },
  ui: {
    toastList: [],
  },
  editor: {
    codeText: {
      "-1": "",
    },
  },
}

export default initialState
