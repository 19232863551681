import { useMemo } from "react"
import * as R from "ramda"
import sqlTasks from "src/data/sqlTasks"
import { shallowEqual } from "react-redux"
import { useTypedSelector } from "src/models/redux"
import { selectCompletedTasks } from "src/models/challenges"
import { ChallengeTypes } from "src/types"

export default function useCompletedTasks() {
  const completedChallenges = useTypedSelector((state) => selectCompletedTasks(state), shallowEqual)

  const completedTasksMapping = useMemo(() => {
    return R.keys(sqlTasks).reduce((acc: ChallengeTypes.CompletedTasksMapping, challengeId: string) => {
      acc[challengeId] = sqlTasks[challengeId].tasks.map((task: ChallengeTypes.Task, idx) => ({
        instructions: task.instructions,
        completed: Boolean(completedChallenges[challengeId]?.[String(idx)]?.completed),
      }))
      return acc
    }, {})
  }, [completedChallenges])

  return { completedTasksMapping }
}
