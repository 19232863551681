import React from "react"
import { EuiPanel, EuiListGroup, EuiText } from "@elastic/eui"
import styled from "styled-components"
import media from "src/utils/media"

import { ChallengeTypes } from "src/types"

type ItemSize = "xs" | "s" | "m" | "l"

const LessonTableOfContentsWrapper = styled.div`
  position: sticky;
  top: 0;
  flex-direction: column;
  margin-right: 2rem;
  max-width: 250px;
  width: 100%;

  display: none;
  ${media.tablet`
    display: flex;
  `}
`
const TocFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 30px;
`
const StyledEuiPanel = styled(EuiPanel)`
  & span {
    font-size: 14px;
  }
`

interface ILessonTableOfContents {
  toc: ChallengeTypes.TableOfContents
}
export default function LessonTableOfContents({ toc }: ILessonTableOfContents) {
  if (!toc?.items) return null
  const tableOfContents = toc.items.map((item) => ({
    label: item.title,
    href: item.url,
    iconType: `link`,
    size: `s` as ItemSize,
  }))

  return (
    <LessonTableOfContentsWrapper>
      <TocFlexContainer>
        <StyledEuiPanel>
          <EuiText>
            <h4>Table Of Contents</h4>
          </EuiText>
          <EuiListGroup listItems={tableOfContents} />
        </StyledEuiPanel>
      </TocFlexContainer>
    </LessonTableOfContentsWrapper>
  )
}
