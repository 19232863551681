import React from "react"
import { EuiPageBody, EuiSpacer, EuiPageContent, EuiText } from "@elastic/eui"
import { DataDescriptionTable } from "src/components"
import styled from "styled-components"

import { ChallengeTypes } from "src/types"

const ChallengeHelpPanelWrapper = styled.div``

interface IChallengeHelpPanel {
  challenge: ChallengeTypes.ChallengeNodeFull
}
export default function ChallengeHelpPanel({ challenge }: IChallengeHelpPanel) {
  const renderDbTitle = () => {
    if (challenge.frontmatter.databaseName === "moviesDb")
      return <h3>You'll be working with the IMDB Movies dataset for this challenge.</h3>
    if (challenge.frontmatter.databaseName === "countriesDb")
      return <h3>You'll be working with the World Bank Countries dataset for this challenge.</h3>
    if (challenge.frontmatter.databaseName === "olympicsDb")
      return <h3>You'll be working with the Winter Olympics database for this challenge.</h3>
    if (challenge.frontmatter.databaseName === "db")
      return <h3>You'll be working with a blank database for this challenge.</h3>
    if (challenge.frontmatter.databaseName === "blogDb")
      return <h3>You'll be working with the fake blog database for this challenge.</h3>
    if (challenge.frontmatter.databaseName === "usersDb")
      return <h3>You'll be working with the celebrity fake twitter database for this challenge.</h3>
  }

  return (
    <ChallengeHelpPanelWrapper>
      <EuiPageBody>
        <EuiPageContent>
          <EuiText>{renderDbTitle()}</EuiText>
          <EuiSpacer size="xl" />
          <DataDescriptionTable databaseName={challenge.frontmatter.databaseName} />
        </EuiPageContent>
        <EuiSpacer size="xl" />
      </EuiPageBody>
      <EuiSpacer size="xl" />
    </ChallengeHelpPanelWrapper>
  )
}
