import React from "react"
import { EuiTitle, EuiText, EuiLoadingChart, EuiSpacer } from "@elastic/eui"
import styled from "styled-components"

const LoadingScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
`

export default function LoadingScreen() {
  return (
    <LoadingScreenWrapper>
      <EuiTitle>
        <h1>Loading Sqlchallenges.io</h1>
      </EuiTitle>
      <EuiText>
        <h3>One moment please...</h3>
      </EuiText>

      <EuiSpacer size="xl" />

      <EuiLoadingChart size="xl" />
    </LoadingScreenWrapper>
  )
}
