import React from "react"
import { EuiLink, EuiHeaderLink, EuiHeaderLogo } from "@elastic/eui"
// import { useNavigate } from "react-router-dom"
import { navigate } from "gatsby"

const isModifiedEvent = (event: MouseEvent) => Boolean(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const isLeftClickEvent = (event: MouseEvent) => event.button === 0

const isTargetBlank = (event: MouseEvent) => {
  if (event !== null && event?.target !== null) {
    // @ts-ignore
    const target = event.target.getAttribute("target")
    return target && target !== "_self"
  }
}

// MouseEventHandler<HTMLAnchorElement>

interface IEuiCustomLink {
  to: string
  isHeader?: boolean
  isHeaderLogo?: boolean
  children?: React.ReactNode
  [key: string]: any
}
export default function EuiCustomLink({ to, isHeader = false, isHeaderLogo = false, ...rest }: IEuiCustomLink) {
  function onClick(event: Event) {
    if (event.defaultPrevented) {
      return
    }

    // Let the browser handle links that open new tabs/windows
    // @ts-ignore
    if (isModifiedEvent(event) || !isLeftClickEvent(event) || isTargetBlank(event)) {
      return
    }

    // Prevent regular link behavior, which causes a browser refresh.
    event.preventDefault()

    // Push the route to the history.
    navigate(to)
  }

  const props = { ...rest, href: to, onClick }
  // @ts-ignore
  if (isHeaderLogo) return <EuiHeaderLogo {...props} />
  // @ts-ignore
  return isHeader ? <EuiHeaderLink {...props} /> : <EuiLink {...props} />
}
