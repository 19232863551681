import * as R from "ramda"
import { isValidId } from "src/utils/parseUtils"
import { arraysAreEquivalentInOrderAndValue, arraysAreEquivalentInValue, sortByFirstItem } from "src/utils/lists"
import { ChallengeTypes, SQLiteDbTypes } from "src/types"

export const coerceNumericIdToString = (id: number): string => {
  return isValidId(id) ? String(id) : "-1"
}

// export const coerceChallengeId = (challengeId: number): string => coerceNumericIdToString(challengeId)
export const coerceTaskId = (taskId: number): string => coerceNumericIdToString(taskId)
export const coerceChallengeId = (challengeId: string): string => challengeId

export const extractLessonNumberFromChallenge = (challenge: ChallengeTypes.ChallengeNodeFull) => {
  const { filePath } = challenge.fields
  if (!filePath) return challenge.frontmatter.id
  const splitPath = filePath.split("-")
  return splitPath[1]
}

export const isTaskCompleted = (
  task: ChallengeTypes.Task,
  taskState: ChallengeTypes.TaskCompletion,
  taskId: number
): boolean => {
  if (task.test({ results: null, db: null })) return true
  return taskState?.currentTask > taskId || taskState?.tasks?.[String(taskId)]?.completed
}

export const determineTaskStatus = (
  task: ChallengeTypes.Task,
  taskState: ChallengeTypes.TaskCompletion,
  taskId: number,
  isProcessing?: boolean,
  failed?: boolean
): "disabled" | "incomplete" | "warning" | "danger" | "loading" | "complete" | undefined => {
  if (isTaskCompleted(task, taskState, taskId)) return "complete"
  if (taskState?.currentTask === taskId) {
    if (isProcessing) return "loading"
    if (failed) return "danger"
    return undefined
  }
  return "incomplete"
}

export const groupChallengeByCategory = R.groupBy((challenge: ChallengeTypes.ChallengeNodeFull) => {
  return challenge.frontmatter.category || "Extra"
})

export const createColumnValuePairsFromResults = (columns: Array<string>, values: Array<any>) => {
  return R.fromPairs(R.zip(columns, values))
}

export const checkIfUserSubmissionMatchesSolution = (
  a: SQLiteDbTypes.SQLiteQueryExecResult,
  b: SQLiteDbTypes.SQLiteQueryExecResult,
  retainOrder: boolean = false
): boolean => {
  if (R.isNil(a?.values) || R.isNil(b?.values)) return false
  if (R.length(a.values) !== R.length(b.values)) return false
  if (!R.isEmpty(R.symmetricDifference(a.values, b.values))) return false

  const setA = a.values.map((a) => [...new Set(a)].sort())
  const setB = b.values.map((b) => [...new Set(b)].sort())

  return R.all(
    (pair: [Array<any>, Array<any>]) => arraysAreEquivalentInOrderAndValue(pair[0], pair[1]),
    retainOrder ? R.zip(setA, setB) : R.zip(sortByFirstItem(setA), sortByFirstItem(setB))
  )
}

export const handleSubmissionAndSolution = (
  queryResults: SQLiteDbTypes.SQLiteQueryExecResult[],
  results: SQLiteDbTypes.SQLiteQueryExecResult[],
  retainOrder: boolean = false
): boolean => {
  // Solution Query
  const { columns, values } = queryResults[0]
  const loweredCols = R.map((c) => R.toLower(c), columns)
  const queryResultsObjects = values.map((v) => createColumnValuePairsFromResults(loweredCols, v))
  // Submission Query
  const loweredResultsCols = R.map((c) => R.toLower(c), results?.[0]?.columns ?? [])
  const resultsObjects = (results?.[0]?.values ?? []).map((v) =>
    createColumnValuePairsFromResults(loweredResultsCols, v)
  )

  return retainOrder
    ? arraysAreEquivalentInOrderAndValue(queryResultsObjects, resultsObjects)
    : arraysAreEquivalentInValue(queryResultsObjects, resultsObjects)
}
