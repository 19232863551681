// Each challenge task should have an instructions and test property.
// The instructions property is what will be displayed.
// The test property should be a function that returns true or false based on the results provided to it from the query

import * as R from "ramda"
import { ChallengeTypes } from "src/types"
import {
  checkIfUserSubmissionMatchesSolution,
  createColumnValuePairsFromResults,
  handleSubmissionAndSolution,
} from "src/utils/challenges"
import { arraysAreEquivalentInValue } from "src/utils/lists"

const isDefined = (v: any) => !R.isNil(v)

const challengeTasks: Record<string, { tasks: ChallengeTypes.Task[] }> = {
  "0.0": {
    tasks: [
      {
        instructions: `Read the lesson.`,
        test: () => {
          return true
          // if (R.isNil(p)) return false
          // const { results, db } = p
          // if (R.isNil(results)) return false
          // if (R.isNil(db)) return false
          // return true
        },
      },
      {
        instructions: `Enter in a valid SQL statement using the examples from the lesson and hit the "Execute Query" button.`,
        test: (p) => {
          if (R.isNil(p)) return false
          const { results } = p
          return isDefined(results)
        },
        codeSample: `SELECT 1;`,
      },
    ],
  },
  "0.1": {
    tasks: [
      {
        instructions: "Select the `year` where the United States had a `gdp` of more than 20 trillion.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solution_sql = `
SELECT year
FROM   countries_of_the_world
WHERE  country_name = 'United States' 
  AND  gdp > 20000000000000;
          `.trim()
          const solutionResults = db.exec(solution_sql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0])
        },
      },
      {
        instructions:
          "Select the `country_name` and `region` of all countries in either the `MIDDLE EAST & NORTH AFRICA` region or the `SUB-SAHARAN AFRICA` region." +
          "\n\n" +
          "Return only the first 10 rows.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solution_sql = `
SELECT country_name, region
FROM countries_of_the_world
WHERE region = 'MIDDLE EAST & NORTH AFRICA' 
   OR region = 'SUB-SAHARAN AFRICA'
LIMIT 10;
          `.trim()
          const solutionResults = db.exec(solution_sql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0])
        },
      },
      {
        instructions:
          "Select the `country_name`, `land_area`, and `population` of all countries in the `LATIN AMERICA & THE CARIBBEAN` region - except Brazil." +
          "\n\n" +
          "Filter results to the year `2018`, and to only include countries where the `population` is greater than 10 million, but the `land_area` is less than 1 million km." +
          "\n\n" +
          "(Hint: You'll probably want to use _at least_ 3 `AND` operators!)",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solutionSql = `
SELECT country_name, land_area, population
FROM countries_of_the_world
WHERE region = 'LATIN AMERICA & THE CARIBBEAN' 
  AND country_name != 'Brazil'
  AND year = 2018
  AND population > 10000000
  AND land_area  < 1000000;
          `.trim()
          const solutionResults = db.exec(solutionSql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0])
        },
      },
    ],
  },
  "0.2": {
    tasks: [
      {
        instructions:
          "Write a query to select the `title`, `release_date`, `budget` (in millions), and `revenue` (in millions) of all movies in the 1990s." +
          "\n\n" +
          "Filter the query to only movies with `revenue` more than $100 million and having a `vote_average` of at least 8. Order results by revenue in descending order.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db, retainOrder = true } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solutionSql = `
SELECT title, 
        release_date,
        budget / 1000000 AS budget_in_millions,
        revenue / 1000000 AS revenue_in_millions
FROM  movies
WHERE revenue > 100000000
  AND vote_average >= 8
  AND release_date > '1990-01-01'
  AND release_date < '2000-01-01'
ORDER BY revenue DESC;
          `.trim()

          const solutionResults = db.exec(solutionSql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0], retainOrder)
        },
      },
      {
        instructions: "Find the title and `release_date` of the first movie with a budget over $100 million.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db, retainOrder = false } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solutionSql = `
SELECT title, MIN(release_date)
FROM movies
WHERE budget > 100000000;                      
          `.trim()

          const solutionResults = db.exec(solutionSql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0], retainOrder)
        },
      },
    ],
  },
  "0.3": {
    tasks: [
      {
        instructions:
          "How many medals did Norway win in each of the Winter Olympics since the year 2000? Select the `year` and number of medals.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db, retainOrder = false } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solutionSql = `
SELECT year, COUNT(medal)
FROM winter_olympics
WHERE team = 'Norway' AND year >= 2000
GROUP BY year;
          `.trim()

          const solutionResults = db.exec(solutionSql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0], retainOrder)
        },
      },
      {
        instructions:
          "Select the `year` and the total number of unique events in every Winter Olympics since it began. Sort the results bu number of unique events in descending order .",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solutionSql = `
SELECT year, 
       COUNT(DISTINCT(event)) AS unique_events
FROM winter_olympics
GROUP BY year
ORDER BY unique_events DESC;                  
          `.trim()

          const solutionResults = db.exec(solutionSql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0], true)
        },
      },
      {
        instructions:
          "Time to see which countries dominated in particular Olympic tournaments. " +
          "Find the `team`, `city, `year`, and total number of **unique** events each country won gold medals in. " +
          "\n\n" +
          "Sort the results by number of gold medals in descending order and take only the first 20 rows.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solutionSql = `
SELECT team, 
       city, 
       year,
       COUNT(DISTINCT(event)) AS gold_medals
FROM winter_olympics
WHERE medal = 'Gold'
GROUP BY team, year
ORDER BY gold_medals DESC
LIMIT 20;
          `.trim()

          const solutionResults = db.exec(solutionSql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0], true)
        },
      },
      {
        instructions:
          "Let's examine the countries that are no longer active in the Winter Olympics, or have been down on their luck in the last few decades." +
          "\n\n" +
          "Find the `team`, total number of medals, and the rounded average height of all winter olympics participants from that team. Filter the results to only " +
          "include countries where no participant from that country has won a medal in an event **after the year 1990**." +
          "\n\n" +
          "Sort the results by the number of medals they won.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const solutionSql = `
SELECT team, 
       COUNT(medal) AS medal_count, 
       ROUND(AVG(height)) AS average_height
FROM winter_olympics
GROUP BY team
HAVING MAX(year) <= 1990
ORDER BY medal_count DESC;
          `.trim()

          const solutionResults = db.exec(solutionSql)

          return checkIfUserSubmissionMatchesSolution(results?.[0] || [], solutionResults[0], true)
        },
      },
    ],
  },
  "1.0": {
    tasks: [
      {
        instructions:
          "Create the first of two new tables. The first table should be called `musical_artists` and contain columns for " +
          "`name` (text), `total_band_members` (integer), `debut_album_year` (integer), and `active`." +
          "\n\n The `active` column should behave as a boolean and default to 1, and the name column should be unique." +
          "No null values allowed.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const pragmaResults = db.exec(`SELECT * FROM pragma_table_info("musical_artists");`)
          if (R.isEmpty(pragmaResults)) return false
          const { columns, values } = pragmaResults[0]
          const loweredCols = R.map((c) => R.toLower(c), columns)
          const resultsObjects = values.map((v) => createColumnValuePairsFromResults(loweredCols, v))

          const expectedResults = [
            {
              cid: 0,
              dflt_value: null,
              name: "name",
              notnull: 1,
              pk: 0,
              type: "TEXT",
            },
            {
              cid: 1,
              dflt_value: null,
              name: "total_band_members",
              notnull: 1,
              pk: 0,
              type: "INTEGER",
            },
            {
              cid: 2,
              dflt_value: null,
              name: "debut_album_year",
              notnull: 1,
              pk: 0,
              type: "INTEGER",
            },
            {
              cid: 3,
              dflt_value: "1",
              name: "active",
              notnull: 1,
              pk: 0,
              type: "INTEGER",
            },
          ]

          return arraysAreEquivalentInValue(resultsObjects, expectedResults)
        },
      },
      {
        instructions:
          "Create the second of two tables - the `songs` table. This table should have 4 columns: \n\n" +
          "The `title` (text) column, the `total_seconds` (integer) column, the `artist_name` (text) column, and the `is_single` (integer) column." +
          "\n\n" +
          "The `artist_name` column here is meant to reference the `musical_artists` table and " +
          "the `is_single` column is meant to act as a boolean with a default of 0. No null values here either.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const pragmaResults = db.exec(`SELECT * FROM pragma_table_info("songs");`)

          if (R.isEmpty(pragmaResults)) return false
          const { columns, values } = pragmaResults[0]
          const loweredCols = R.map((c) => R.toLower(c), columns)
          const resultsObjects = values.map((v) => createColumnValuePairsFromResults(loweredCols, v))

          const expectedResults = [
            {
              cid: 0,
              dflt_value: null,
              name: "title",
              notnull: 1,
              pk: 0,
              type: "TEXT",
            },
            {
              cid: 1,
              dflt_value: null,
              name: "total_seconds",
              notnull: 1,
              pk: 0,
              type: "INTEGER",
            },
            {
              cid: 2,
              dflt_value: null,
              name: "artist_name",
              notnull: 1,
              pk: 0,
              type: "TEXT",
            },
            {
              cid: 3,
              dflt_value: "0",
              name: "is_single",
              notnull: 1,
              pk: 0,
              type: "INTEGER",
            },
          ]

          return arraysAreEquivalentInValue(resultsObjects, expectedResults)
        },
      },
      {
        instructions:
          "Insert two new artists into the `musical_artists` table. \n\n" +
          "The first record should be for 'Shakira' who got her start in 1990 and is still active. " +
          "The second should be for 'Black Eyed Peas' - currently composed of 4 members - who released their debut album in 1998 and are also still active.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT *
FROM musical_artists;          
          `

          const queryResults = db.exec(query)
          if (R.isEmpty(queryResults)) return false
          const { columns, values } = queryResults[0]
          const loweredCols = R.map((c) => R.toLower(c), columns)
          const resultsObjects = values.map((v) => createColumnValuePairsFromResults(loweredCols, v))

          const expectedResults = [
            {
              active: 1,
              debut_album_year: 1990,
              name: "Shakira",
              total_band_members: 1,
            },
            {
              active: 1,
              debut_album_year: 1998,
              name: "Black Eyed Peas",
              total_band_members: 4,
            },
          ]

          return arraysAreEquivalentInValue(resultsObjects, expectedResults)
        },
      },
      {
        instructions:
          "Now add 3 new records to the `songs` table. \n\nThe first should be Shakira's hit single `'Hips Dont Lie'` with a runtime of `218` seconds. " +
          "The second should be Shakira's 2017 single `'Chantaje'` with a runtime of `196` seconds. The last one should be Black Eyed Peas 2003 " +
          "classic `'Where Is The Love'`. This single ran for `274` seconds." +
          "\n\n Note that we have intentionally removed any puncutation or special characters.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT *
FROM songs;          
          `

          // const insertQuery = `
          // INSERT INTO songs (title, total_seconds, artist_name, is_single)
          // VALUES ('Hips Dont Lie', 218, 'Shakira', 1),
          //         ('Chantaje', 196, 'Shakira', 1),
          //         ('Where Is The Love', 274, 'Black Eyed Peas', 1);
          // `

          const queryResults = db.exec(query)
          if (R.isEmpty(queryResults)) return false
          const { columns, values } = queryResults[0]
          const loweredCols = R.map((c) => R.toLower(c), columns)
          const resultsObjects = values.map((v) => createColumnValuePairsFromResults(loweredCols, v))

          // console.log({ resultsObjects })

          const expectedResults = [
            {
              artist_name: "Black Eyed Peas",
              is_single: 1,
              title: "Where Is The Love",
              total_seconds: 274,
            },
            {
              artist_name: "Shakira",
              is_single: 1,
              title: "Hips Dont Lie",
              total_seconds: 218,
            },
            {
              artist_name: "Shakira",
              is_single: 1,
              title: "Chantaje",
              total_seconds: 196,
            },
          ]

          return arraysAreEquivalentInValue(resultsObjects, expectedResults)
        },
      },
      {
        instructions:
          "Modify the Black Eyed Peas song to use the song length of the radio edit version - `231` seconds.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT *
FROM songs;          
          `

          // const updateQuery = `
          // UPDATE songs
          // SET total_seconds = 231
          // WHERE title = 'Where Is The Love';
          // `

          const queryResults = db.exec(query)
          if (R.isEmpty(queryResults)) return false
          const { columns, values } = queryResults[0]
          const loweredCols = R.map((c) => R.toLower(c), columns)
          const resultsObjects = values.map((v) => createColumnValuePairsFromResults(loweredCols, v))

          const expectedResults = [
            {
              artist_name: "Black Eyed Peas",
              is_single: 1,
              title: "Where Is The Love",
              total_seconds: 231,
            },
            {
              artist_name: "Shakira",
              is_single: 1,
              title: "Hips Dont Lie",
              total_seconds: 218,
            },
            {
              artist_name: "Shakira",
              is_single: 1,
              title: "Chantaje",
              total_seconds: 196,
            },
          ]

          return arraysAreEquivalentInValue(resultsObjects, expectedResults)
        },
      },
    ],
  },
  "1.1": {
    tasks: [
      {
        instructions:
          "Select the `email`, `username`, `first_name`, and `last_name` of the user with the username of `comedy_doctor`.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT email, username, first_name, last_name
FROM users
  JOIN profiles ON profiles.user_id = users.id
WHERE username = 'comedy_doctor';         
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results)
        },
      },
      {
        instructions:
          "Write a query to determine the number of comments each user left and the `email` of that user. Alias the number of comments as `total_comments`." +
          "\n\n" +
          "Order the query by `total_comments` in descending order and take only the top 10.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT COUNT(user_id) AS total_comments, email
FROM comments
    JOIN users 
    ON comments.user_id = users.id
GROUP BY user_id
ORDER BY total_comments DESC
LIMIT 10;          
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results, true)
        },
      },
      {
        instructions:
          "Query the `id` and `comment` of every comment made and display the `title` of that comment's post, along with the comment author's `first_name` and `last_name`.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT comments.id,
       comments.comment,
       posts.title,
       profiles.first_name,
       profiles.last_name
FROM comments
      JOIN profiles ON comments.user_id = profiles.user_id
      JOIN posts ON comments.post_id = posts.id;          
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results)
        },
      },
    ],
  },
  "1.2": {
    tasks: [
      {
        instructions:
          "Write a query to select the `email` of every user along with the number of tweets they've posted. " +
          "Make sure to include users who haven't posted any tweets. " +
          "\n\n" +
          "Alias the number of tweets as `total_tweets` and sort the results in descending order by `total_tweets`." +
          "",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT users.email,
       COUNT(tweets.id) AS total_tweets
FROM users
    LEFT JOIN tweets ON users.id = tweets.user_id
GROUP BY users.id
ORDER BY total_tweets DESC;
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results, true)
        },
      },
      {
        instructions:
          "Get the `email` and number of followers for every user that has more followers than the average user. " +
          "Alias the number of followers as `num_followers` and `email` as `followed_email`." +
          "\n\n" +
          "Sort the results in descending order by number of followers." +
          "",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT COUNT(follower.email) AS num_followers,
       followed.email AS followed_email
FROM followers_to_following AS ftf
    JOIN users AS follower ON follower.id = ftf.follower_id
    JOIN users AS followed ON followed.id = ftf.following_id
GROUP BY followed_email
HAVING num_followers > (
    SELECT AVG(follower_count.amt)
    FROM (
        SELECT COUNT(ftf_avg.follower_id) AS amt
        FROM followers_to_following AS ftf_avg
        GROUP BY ftf_avg.following_id
    ) AS follower_count
)
ORDER BY num_followers DESC;
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results, true)
        },
      },
    ],
  },
  "1.3": {
    tasks: [
      {
        instructions: "Get the `title` of every movie that is in all caps.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT title
FROM movies
WHERE UPPER(title) = title;
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results, false)
        },
      },
      {
        instructions:
          "Select the `title` of every movie that starts with `The` and has at least a 9000 `vote_count`. " +
          "Replace the word `Dark` in each title with an empty string.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT REPLACE(title, 'Dark', '')
FROM movies
WHERE vote_count > 9000
  AND title LIKE 'The%';       
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results, false)
        },
      },
      {
        instructions:
          "Write a query to get the number of movies released on Christmas day since the year 2000. " +
          "Alias the amount as `total_xmas_movies`.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT COUNT(*) AS total_xmas_movies
FROM movies
WHERE strftime('%d', release_date) = '25'
  AND strftime('%m', release_date) = '12'
  AND release_date > '2000';
          `.trim()

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results, false)
        },
      },
      {
        instructions:
          "Find the movie that grossed the most money (in millions) for each decade. " +
          "\n\n" +
          "Select the `title` column from the `movies` table, and use scalar and date functions to compute the `year` and `decade` columns for each movie. " +
          "The `decade` column should appear as four numbers, like `1980`, `1990`, or `2000`." +
          "\n\n" +
          "Calculate the total gross of the movie using the `budget` and `revenue` columns, convert it to millions (2,500,000 becomes 2.5), and alias it as `total_gross`. " +
          "\n\n" +
          "Order the results by `release_date` in descending order.",
        test: (p) => {
          if (R.isNil(p)) return false
          const { results, db } = p
          if (R.isNil(results)) return false
          if (R.isNil(db)) return false

          const query = `
SELECT title,
       strftime('%Y', release_date) AS year,
       SUBSTRING(
         strftime('%Y', release_date), 
         1, 
         3
       ) || '0' AS decade,
       MAX((revenue - budget) / 1000000) AS total_gross
FROM movies
GROUP BY decade
ORDER BY release_date DESC; 
          `

          const queryResults = db.exec(query)

          return handleSubmissionAndSolution(queryResults, results, true)
        },
      },
    ],
  },
}

export default challengeTasks
