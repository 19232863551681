import React from "react"
import styled from "styled-components"
import { UiTypes } from "src/types"

const StyledSVG = styled.svg``

const Codesandbox: React.FC<UiTypes.IIconProps> = ({ stroke = "#212121", className = "" }) => (
  <StyledSVG
    className={className}
    stroke={stroke}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    focusable="false"
    role="img"
    aria-hidden="true"
  >
    <path d="M14.738 28.044V16.681L3.172 10.919v6.46l5.32 2.67v4.889l6.246 3.106zm2.344.066l6.357-3.17v-5.002l5.353-2.686V10.87l-11.71 5.854V28.11zM27.306 8.993l-6.003-3.012-5.286 2.656-5.325-2.659L4.637 9.03l11.317 5.638 11.352-5.675zM.828 23.744V8.324L15.981.689l15.155 7.604V23.72L15.98 31.28.828 23.743z"></path>
  </StyledSVG>
)

export default Codesandbox
