import React from "react"
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiListGroup,
  EuiListGroupItem,
  EuiText,
  EuiTitle,
  EuiSpacer,
} from "@elastic/eui"
import { EuiCustomLink } from "src/components"
import styled from "styled-components"
import { groupChallengeByCategory } from "src/utils/challenges"
import { ChallengeTypes } from "src/types"

const ChallengeListingWrapper = styled.div`
  & .challenge-number {
    width: 50px;
  }

  & p {
    margin-bottom: 0;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
  }
`

interface IChallengeTasksListing {
  challenge: ChallengeTypes.ChallengeNodeFull
  completedTasksMapping: ChallengeTypes.CompletedTasksMapping
}
const ChallengeTasksListing: React.FC<IChallengeTasksListing> = ({ challenge, completedTasksMapping }) => {
  const tasks = completedTasksMapping[challenge.frontmatter.challengeId]

  return (
    <EuiListGroup flush color="primary">
      {tasks.map((task, idx) => (
        <EuiListGroupItem
          key={`${challenge.frontmatter.challengeId}-${idx}`}
          icon={
            task.completed ? (
              <EuiIcon type="checkInCircleFilled" color="primary" />
            ) : (
              <EuiIcon type="crossInACircleFilled" color="subdued" />
            )
          }
          label={`Task ${idx + 1}`}
          // onClick={() => {}}
          isActive
          href={`/challenges${challenge.fields.slug}/`}
          color={task.completed ? "primary" : "subdued"}
        />
      ))}
    </EuiListGroup>
  )
}

interface ISingleChallenge {
  challenge: ChallengeTypes.ChallengeNodeFull
  completedTasksMapping: ChallengeTypes.CompletedTasksMapping
}
const SingleChallenge: React.FC<ISingleChallenge> = ({ challenge, completedTasksMapping }) => {
  return (
    <React.Fragment key={challenge.frontmatter.id}>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <div className="challenge-number">
            <EuiTitle>
              <h4>{challenge.frontmatter.challengeId}</h4>
            </EuiTitle>
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <EuiCustomLink to={`/challenges${challenge.fields.slug}/`}>
              <p>{challenge.frontmatter.title}</p>
            </EuiCustomLink>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <ChallengeTasksListing completedTasksMapping={completedTasksMapping} challenge={challenge} />
    </React.Fragment>
  )
}

interface IChallengeListing {
  edges: Array<{ node: ChallengeTypes.ChallengeNodeFull }>
  completedTasksMapping: ChallengeTypes.CompletedTasksMapping
}
export default function CompletedChallengeListing({ edges, completedTasksMapping }: IChallengeListing) {
  const challenges = edges.map(({ node }) => node)

  const groupedChallenges = React.useMemo(() => groupChallengeByCategory(challenges), [challenges])

  return (
    <ChallengeListingWrapper>
      <EuiText>
        <h3>Intro</h3>
      </EuiText>
      {groupedChallenges.intro.map((challenge) => (
        <SingleChallenge
          challenge={challenge}
          completedTasksMapping={completedTasksMapping}
          key={challenge.fields.slug}
        />
      ))}
      <EuiSpacer size="m" />
      <EuiText>
        <h3>Intermediate</h3>
      </EuiText>
      <EuiSpacer size="s" />
      {groupedChallenges.intermediate.map((challenge) => (
        <SingleChallenge
          challenge={challenge}
          completedTasksMapping={completedTasksMapping}
          key={challenge.fields.slug}
        />
      ))}
      <EuiSpacer size="s" />
    </ChallengeListingWrapper>
  )
}
