import React from "react"
import { EuiBasicTable } from "@elastic/eui"
import styled from "styled-components"

const ComparisonOperatorsTableWrapper = styled.div`
  max-width: 400px;
  margin-top: 1.5rem;

  & table {
    border: solid 1px #d3dae6;
    border-radius: 4px;

    & thead {
      background: #e3eaf6;
    }
  }
`

const items = [
  { Comparison: `Equal`, Operator: "=", Example: `country_name = 'Mexico'` },
  { Comparison: `Not equal`, Operator: "!= or <>", Example: `country_name != 'Argentina'` },
  { Comparison: `Greater than`, Operator: ">", Example: `population > 1000` },
  { Comparison: `Less than`, Operator: "<", Example: `gdp < 1000000` },
  { Comparison: `Greater than or equal`, Operator: ">=", Example: `life_expectancy >= 80` },
  { Comparison: `Less than or equal`, Operator: "<=", Example: `birth_rate <= 25.5` },
]

const columns = [
  {
    field: `Operator`,
    name: `Operator`,
    truncateText: false,
  },
  {
    field: `Comparison`,
    name: `Comparison`,
    truncateText: false,
  },
  {
    field: `Example`,
    name: `Example`,
    truncateText: false,
  },
]

export default function ComparisonOperatorsTable() {
  return (
    <ComparisonOperatorsTableWrapper>
      <>
        <EuiBasicTable tableLayout="fixed" responsive={false} items={items} columns={columns} />
      </>
    </ComparisonOperatorsTableWrapper>
  )
}
