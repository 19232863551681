export enum CodeEditorActionType {
  SET_CODE_TEXT = "@@editor/SET_CODE_TEXT",
  RESET_CODE_TEXT = "@@editor/RESET_CODE_TEXT",
}

export interface CodeEditorState {
  codeText: Record<string, string>
}

export interface ISetCodeText {
  type: CodeEditorActionType.SET_CODE_TEXT
  text: string
  challengeId: string
}
export interface IResetCodeText {
  type: CodeEditorActionType.RESET_CODE_TEXT
  challengeId: string
}

export type CodeEditorAction = ISetCodeText | IResetCodeText
