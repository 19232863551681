import { randomLcg as d3RandomLcg } from "d3-random"
import { shuffler as d3Shuffler } from "d3-array"

import { SQLiteDbTypes } from "src/types"
import { SqlValue } from "sql.js"

/********************
        USERS
 ********************/

export const newUsersSql = `
  CREATE TABLE IF NOT EXISTS users (
    id       INTEGER PRIMARY KEY,
    username TEXT NOT NULL,
    email    TEXT NOT NULL,
    password TEXT NOT NULL,
    is_admin INTEGER NOT NULL
  );

  CREATE TABLE IF NOT EXISTS profiles (
    id          INTEGER PRIMARY KEY,
    first_name  TEXT,
    last_name   TEXT,
    bio         TEXT,
    user_id     INTEGER NOT NULL,
    FOREIGN KEY (user_id) REFERENCES users(id)
  );

  CREATE TABLE IF NOT EXISTS tweets (
    id          INTEGER PRIMARY KEY,
    tweet       TEXT NOT NULL,
    user_id     INTEGER NOT NULL,
    timestamp   TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
    FOREIGN KEY (user_id) REFERENCES users(id)
  );

  CREATE TABLE IF NOT EXISTS favorites (
    tweet_id    INTEGER NOT NULL,
    user_id     INTEGER NOT NULL,
    FOREIGN KEY (tweet_id) REFERENCES tweets(id),
    FOREIGN KEY (user_id)  REFERENCES users(id),
    PRIMARY KEY (tweet_id, user_id)
  );

  CREATE TABLE IF NOT EXISTS followers_to_following (
    follower_id  INTEGER NOT NULL,
    following_id INTEGER NOT NULL,
    FOREIGN KEY  (follower_id)  REFERENCES users(id),
    FOREIGN KEY  (following_id) REFERENCES users(id),
    PRIMARY KEY (follower_id, following_id)
  );
`.trim()

const sampleUserData = {
  users: [
    // include fake celebrity data here
    {
      username: `kid_from_akron`,
      email: `lebron@james.io`,
      password: `lebronjames`,
      is_admin: 0,
      profile: {
        first_name: `Lebron`,
        last_name: `James`,
      },
    },
    {
      username: `the_voice`,
      email: `blake@shelton.io`,
      password: `countrymusic`,
      is_admin: 0,
      profile: {
        first_name: `Blake`,
        last_name: `Shelton`,
      },
    },
    {
      username: `tennistwins`,
      email: `serena@williams.io`,
      password: `serenawilliams`,
      is_admin: 0,
      profile: {
        first_name: `Serena`,
        last_name: `Williams`,
      },
    },
    {
      username: `jlo`,
      email: `jennifer@lopez.io`,
      password: `jenny_from_the_block`,
      is_admin: 0,
      profile: {
        first_name: `Jennifer`,
        last_name: `Lopez`,
      },
    },
    {
      username: `martialarts`,
      email: `bruce@lee.io`,
      password: `bruce_lee`,
      is_admin: 0,
      profile: {
        first_name: `Bruce`,
        last_name: `Lee`,
      },
    },
    {
      username: `haroldandkumar`,
      email: `kal@penn.io`,
      password: `kal_penn`,
      is_admin: 0,
      profile: {
        first_name: `Kal`,
        last_name: `Penn`,
      },
    },
    {
      username: `usa_womens_soccer`,
      email: `alex@morgan.io`,
      password: `alex_morgan`,
      is_admin: 0,
      profile: {
        first_name: `Alex`,
        last_name: `Morgan`,
      },
    },
    {
      username: `yankees_slugger`,
      email: `giancarlo@stanton.io`,
      password: `giancarlostanton`,
      is_admin: 0,
      profile: {
        first_name: `Giancarlo`,
        last_name: `Stanton`,
      },
    },
    {
      username: `madd_city`,
      email: `kendrick@lamar.io`,
      password: `mad_city`,
      is_admin: 0,
      profile: {
        first_name: `Kendrick`,
        last_name: `Lamar`,
      },
    },
    {
      username: `comedy_doctor`,
      email: `ken@jeong.io`,
      password: `ken_jeong`,
      is_admin: 0,
      profile: {
        first_name: `Ken`,
        last_name: `Jeong`,
      },
    },
    {
      username: `scary_books`,
      email: `steven@king.io`,
      password: `steven_king`,
      is_admin: 0,
      profile: {
        first_name: `Steven`,
        last_name: `King`,
      },
    },
    {
      username: `human_relationships`,
      email: `esther@perel.io`,
      password: `ester_perel`,
      is_admin: 0,
      profile: {
        first_name: `Esther`,
        last_name: `Perel`,
      },
    },
    {
      username: `tswift`,
      email: `taylor@swift.io`,
      password: `taylor_swift`,
      is_admin: 0,
      profile: {
        first_name: `Taylor`,
        last_name: `Swift`,
      },
    },
    {
      username: `standup`,
      email: `dave@chappelle.io`,
      password: `standup`,
      is_admin: 0,
      profile: {
        first_name: `Dave`,
        last_name: `Chapelle`,
      },
    },
    {
      username: `queen_bey`,
      email: `beyonce@knowles.io`,
      password: `beyonce`,
      is_admin: 0,
      profile: {
        first_name: `Beyonce`,
        last_name: `Knowles`,
      },
    },
    {
      username: `shakira_shakira`,
      email: `shakira@shakira.io`,
      password: `shakira`,
      is_admin: 0,
      profile: {
        first_name: `Shakira`,
        last_name: `Shakira`,
      },
    },
    {
      username: `childish_gambino`,
      email: `donald@glover.io`,
      password: `donald_glover`,
      is_admin: 0,
      profile: {
        first_name: `Donald`,
        last_name: `Glover`,
      },
    },
    {
      username: `vera_fashion`,
      email: `vera@wang.io`,
      password: `vera_wang`,
      is_admin: 0,
      profile: {
        first_name: `Vera`,
        last_name: `Wang`,
      },
    },
    {
      username: `chess_master`,
      email: `garry@kasparov.io`,
      password: `kasparaov`,
      is_admin: 0,
      profile: {
        first_name: `Garry`,
        last_name: `Kasparov`,
      },
    },
  ],
}

type Tweet = {
  userEmail: string
  tweet: string
  favorites: number
  daysBack: number
  hoursBack: number
  minutesBack: number
}
const celebrityTweets: Tweet[] = [
  {
    userEmail: `lebron@james.io`,
    tweet: `Devin Booker is the most disrespected player in our league!!! Simple as that.`,
    favorites: 5,
    daysBack: 4,
    hoursBack: 20,
    minutesBack: 18,
  },
  {
    userEmail: `shakira@shakira.io`,
    tweet: `On Tuesdays we listen to Metallica and we paint our nails fluorescent blue.`,
    favorites: 8,
    daysBack: 2,
    hoursBack: 5,
    minutesBack: 9,
  },
  {
    userEmail: `jennifer@lopez.io`,
    tweet: `Happy Monday to my #JLovers! Let’s have a good week.`,
    favorites: 6,
    daysBack: 11,
    hoursBack: 9,
    minutesBack: 21,
  },
  {
    userEmail: `ken@jeong.io`,
    tweet: `Happy Mother’s Day!!!`,
    favorites: 4,
    daysBack: 7,
    hoursBack: 11,
    minutesBack: 50,
  },
  {
    userEmail: `serena@williams.io`,
    tweet: `Congrats team USA! Billie says it best here! So happy for each one of you and so proud.`,
    favorites: 10,
    daysBack: 5,
    hoursBack: 14,
    minutesBack: 34,
  },
  {
    userEmail: `esther@perel.io`,
    tweet: `I think, in general, when people live in acute stress, either the cracks in their relationship will be amplified or the light that shines through the cracks will be amplified. You get an amplification of the best and of the worst.`,
    favorites: 3,
    daysBack: 4,
    hoursBack: 16,
    minutesBack: 46,
  },
  {
    userEmail: `beyonce@knowles.io`,
    tweet: `Hey World, Its B!
    Im so excited to invite you to my new beyonce.com - weve been working
    hard, and its finally ready for you XO
    `.trim(),
    favorites: 5,
    daysBack: 19,
    hoursBack: 2,
    minutesBack: 0,
  },
  {
    userEmail: `donald@glover.io`,
    tweet: `saw people on here havin a discussion about how tired they were of reviewing boring stuff - tv & film.`,
    favorites: 2,
    daysBack: 27,
    hoursBack: 4,
    minutesBack: 55,
  },
  {
    userEmail: `kendrick@lamar.io`,
    tweet: `My name, is my name. Ask me again, ill tell you the same.`,
    favorites: 4,
    daysBack: 7,
    hoursBack: 7,
    minutesBack: 7,
  },
  {
    userEmail: `vera@wang.io`,
    tweet: `Happy International women’s day to ALL women. Love Vera XX`,
    favorites: 12,
    daysBack: 33,
    hoursBack: 22,
    minutesBack: 11,
  },
  {
    userEmail: `garry@kasparov.io`,
    tweet: `To become a classic, you must know the classics. The pyramid of Magnus chess knowledge reaches highest due to a broader and sturdier base.`,
    favorites: 7,
    daysBack: 1,
    hoursBack: 2,
    minutesBack: 3,
  },
  {
    userEmail: `kal@penn.io`,
    tweet: `Billionaires are so weird.`,
    favorites: 8,
    daysBack: 1,
    hoursBack: 20,
    minutesBack: 13,
  },
  {
    userEmail: `kal@penn.io`,
    tweet: `Hey check on your elderly neighbors who live alone. My neighbor doesnt have internet, so we ordered her some stuff online so she doesnt need to go outside. Shes very cranky about it but were still tight.`,
    favorites: 9,
    daysBack: 2,
    hoursBack: 3,
    minutesBack: 4,
  },
  {
    userEmail: `taylor@swift.io`,
    tweet: `Been in the studio all day recording the next one - its really so amazing what you all have done here`,
    favorites: 6,
    daysBack: 16,
    hoursBack: 27,
    minutesBack: 30,
  },
]

const tweetSeed = 9999
const tweetShuffler = d3Shuffler(d3RandomLcg(tweetSeed))
const shuffledCelebrityTweets = tweetShuffler(celebrityTweets)

const insertUserAndProfileData = (db: SQLiteDbTypes.SQLiteDatabase): SqlValue[] => {
  const userIds = sampleUserData.users.map((user) => {
    const query = `
INSERT INTO users (username, email, password, is_admin)
VALUES ('${user.username}', '${user.email}', '${user.password}', ${user.is_admin})
RETURNING id;
    `.trim()
    const results = db.exec(query)
    const id = results[0].values[0][0]
    const profileQuery = `
INSERT INTO profiles (first_name, last_name, user_id)
VALUES ('${user.profile.first_name}', '${user.profile.last_name}', ${id});
    `.trim()
    db.exec(profileQuery)

    return id
  })

  return userIds
}

const getShuffler = () => {
  const seed = 1738
  const shuffler = d3Shuffler(d3RandomLcg(seed))
  return shuffler
}
const getSecondShuffler = () => {
  const seed = 1989
  const shuffler = d3Shuffler(d3RandomLcg(seed))
  return shuffler
}
const getThirdShuffler = () => {
  const seed = 2021
  const shuffler = d3Shuffler(d3RandomLcg(seed))
  return shuffler
}
const getFourthShuffler = () => {
  const seed = 2022
  const shuffler = d3Shuffler(d3RandomLcg(seed))
  return shuffler
}

export const executeSocialQueries = (db: SQLiteDbTypes.SQLiteDatabase, userIds: SqlValue[]) => {
  const socialQueries = []

  for (let i = 0; i < userIds.length; i++) {
    for (let j = i; j < userIds.length; j++) {
      if (j === i) continue

      const followerId = i + 1
      let followedId = j + 1

      // swap out stephen king and lebron
      // or stephen king and shakira
      if (followedId === 11 && followerId !== 1) {
        followedId = 1
      } else if (followedId === 11 && followerId !== 16) {
        followedId = 16
      }

      const forwardFollowingQuery = `
INSERT INTO followers_to_following (follower_id, following_id)
VALUES (${followerId}, ${followedId});
      `.trim()

      socialQueries.push(forwardFollowingQuery)
    }

    for (let j = i; j > 0; j -= 4) {
      if (j === i) continue

      const followerId = i + 1
      let followedId = j + 1

      // swap out stephen king and lebron
      // or stephen king and shakira
      if (followedId === 11 && followerId !== 1) {
        followedId = 1
      } else if (followedId === 11 && followerId !== 16) {
        followedId = 16
      }

      const backwardFollowingQuery = `
INSERT INTO followers_to_following (follower_id, following_id)
VALUES (${followerId}, ${followedId});
      `.trim()

      if (j >= 1) {
        socialQueries.push(backwardFollowingQuery)
      }
    }
  }

  // randomize social queries
  // const shuffledQueries = shuffle(socialQueries)
  const shuffledQueries = getShuffler()(socialQueries)

  // create them with db
  for (let query of shuffledQueries) {
    try {
      db.exec(query)
    } catch (err) {
      // do nothing
    }
  }
}

export const initializeUsersDatabase = (db: SQLiteDbTypes.SQLiteDatabase) => {
  db.exec(newUsersSql)

  const userIds = insertUserAndProfileData(db)

  executeSocialQueries(db, userIds)

  const shuffledIds = getThirdShuffler()([...userIds])

  const favoritesQueries: string[] = []

  let currentFavoritingUserIdx = 0
  for (let i = 0; i < shuffledCelebrityTweets.length; i++) {
    const tweet = shuffledCelebrityTweets[i]
    const results = db.exec(`SELECT id FROM users WHERE email = '${tweet.userEmail}'`)
    const user_id = results?.[0]?.values?.[0]?.[0]

    if (!user_id) {
      console.log("No id for " + tweet.userEmail)
      continue
    }

    const query = `
      INSERT INTO tweets (tweet, user_id, timestamp)
      VALUES (
        '${tweet.tweet}',
        ${user_id},
        datetime(
          strftime('%s', 'now'), 
          'unixepoch', 
          'localtime',
          '-${tweet.daysBack} days',
          '-${tweet.hoursBack} hours',
          '-${tweet.minutesBack} minutes',
          '-${tweet.minutesBack} seconds'
        )
      )
      RETURNING id;
    `.trim()

    const queryResults = db.exec(query)

    const tweetId = queryResults?.[0]?.values?.[0]?.[0]

    if (!tweetId) {
      console.log("No id for tweet of " + tweet.userEmail)
      continue
    }

    new Array(tweet.favorites).fill(0).forEach(() => {
      currentFavoritingUserIdx += 1
      if (currentFavoritingUserIdx >= shuffledIds.length - 1) {
        currentFavoritingUserIdx = 0
      }
      let currentFavoritingUserId = shuffledIds[currentFavoritingUserIdx]
      if (Number(currentFavoritingUserId) === Number(user_id)) {
        currentFavoritingUserIdx += 1
        currentFavoritingUserId = shuffledIds[currentFavoritingUserIdx]
      }

      const favoriteQuery = `
        INSERT INTO favorites (tweet_id, user_id)
        VALUES (${tweetId}, ${currentFavoritingUserId})
      `.trim()

      favoritesQueries.push(favoriteQuery)
    })
  }

  // shuffle favorites queries
  getFourthShuffler()(favoritesQueries).forEach((query) => {
    db.exec(query)
  })
}

/********************
        BLOG
 ********************/

const newBlogSql = `
CREATE TABLE IF NOT EXISTS users (
  id       INTEGER PRIMARY KEY,
  username TEXT NOT NULL,
  email    TEXT NOT NULL,
  password TEXT NOT NULL,
  is_admin INTEGER NOT NULL
);

CREATE TABLE IF NOT EXISTS profiles (
  id          INTEGER PRIMARY KEY,
  first_name  TEXT,
  last_name   TEXT,
  bio         TEXT,
  user_id     INTEGER NOT NULL,
  FOREIGN KEY (user_id) REFERENCES users(id)
);

CREATE TABLE IF NOT EXISTS posts (
  id          INTEGER PRIMARY KEY,  
  title       TEXT UNIQUE NOT NULL,
  content     TEXT NOT NULL,
  published   INTEGER NOT NULL DEFAULT 0,
  user_id     INTEGER NOT NULL,  
  FOREIGN KEY (user_id) REFERENCES users(id)
);

CREATE TABLE IF NOT EXISTS comments (
  id          INTEGER PRIMARY KEY,
  comment     TEXT NOT NULL,
  post_id     TEXT NOT NULL,
  user_id     INTEGER NOT NULL,
  FOREIGN KEY (post_id) REFERENCES posts(id),
  FOREIGN KEY (user_id) REFERENCES users(id)
);
`

const sampleBlogPostData = [
  {
    title: `Post #1 - Getting cozy with javascript so you can change the world.`,
    content: `Lorem ipsum 1`,
  },
  {
    title: `Post #2 - Getting cozy with python for fun and profit.`,
    content: `Lorem ipsum 2`,
  },
  {
    title: `Post #3 - Learn yourself some python so you can change the world.`,
    content: `Lorem ipsum 3`,
  },
  {
    title: `Post #4 - Learn yourself some sql so you can write more code.`,
    content: `Lorem ipsum 4`,
  },
  {
    title: `Post #5 - Getting cozy with sql so you can write more code.`,
    content: `Lorem ipsum 5`,
  },
  {
    title: `Post #6 - Getting cozy with javascript so you can become a snob.`,
    content: `Lorem ipsum 6`,
  },
  {
    title: `Post #7 - How to write sql for fun and profit.`,
    content: `Lorem ipsum 7`,
  },
  {
    title: `Post #8 - Getting cozy with sql so you can reach self-actualization.`,
    content: `Lorem ipsum 8`,
  },
  {
    title: `Post #9 - How to write javascript so you can reach self-actualization.`,
    content: `Lorem ipsum 9`,
  },
  {
    title: `Post #10 - Getting cozy with javascript so you can become a snob.`,
    content: `Lorem ipsum 10`,
  },
  {
    title: `Post #11 - Master sql so you can change the world.`,
    content: `Lorem ipsum 11`,
  },
  {
    title: `Post #12 - Forget about javascript so you can reach self-actualization.`,
    content: `Lorem ipsum 12`,
  },
]
const sampleComments = [
  `Not bad.`,
  `Great work.`,
  `Excellent stuff.`,
  `One of a kind.`,
  `I am impressed.`,
  `Well done.`,
  `Well how about that.`,
  `What a post!`,
  `Fantastic effort.`,
  `Really knocked it out of the park`,
  `Quite fascinating`,
  `Interesting`,
  `Amusing`,
  `Cool`,
  `Ok`,
  `Writing at its finest.`,
  `Github repo plz`,
  `Phenomenal`,
  `Fascinating`,
  `Wow`,
  `Unheard of`,
  `Intriguing`,
  `Tell me more`,
  `Hot dang.`,
  `Lovely`,
  `Miraculous`,
  `Magnificent`,
  `Wonderful`,
  `Immaculate`,
]

const shuffledComments = getShuffler()(sampleComments)

export const initializeBlogDb = (db: SQLiteDbTypes.SQLiteDatabase) => {
  db.exec(newBlogSql)

  const userIds = insertUserAndProfileData(db)

  // randomize data
  const shuffledIds = getSecondShuffler()(userIds)

  const commentsPerPost = [2, 5, 2, 0, 6, 1, 9, 7, 0, 3, 2, 2]
  let currentComment = 0
  let commenterId = 1

  for (let i = -1; i < sampleBlogPostData.length - 1; i++) {
    const idx = Math.max(0, i)
    const { title, content } = sampleBlogPostData[i + 1]
    const userId = shuffledIds[idx]

    const commentsNeeded = commentsPerPost[i + 1]
    const published = Boolean(commentsNeeded)

    db.exec(`
INSERT INTO posts (title, content, published, user_id)
VALUES ('${title}', '${content}', ${published}, ${userId})    
    `)

    new Array(commentsNeeded).fill(0).forEach((_, iter: number) => {
      const comment = shuffledComments[currentComment]
      currentComment += 1
      if (currentComment >= shuffledComments.length) {
        currentComment = 0
      }
      const postId = i + 1 + 1

      let commentingUserId = commenterId + iter + i
      if (commentingUserId >= shuffledIds.length) {
        commentingUserId = iter
      }
      commenterId += 1

      db.exec(`
INSERT INTO comments (comment, post_id, user_id)
VALUES ('${comment}', ${postId}, ${commentingUserId})
      `)
    })
  }
}

/******
 * UTILITIES
 */

export const blogPostTitleRandomizationSQL = `
WITH parts AS (
  WITH RECURSIVE cnt(x) AS (
      SELECT 1
      UNION ALL
      SELECT x+1 FROM cnt
      LIMIT 10
  )
  SELECT 'Post #' || x AS title ,
      CASE (
          ABS(RANDOM()) % (3 - 0) + 0
      )
      -- ABS(RANDOM()) % (HIGH - LOW) + LOW
        WHEN 0 THEN ' - How to write '
        WHEN 1 THEN ' - Learn yourself some '
        WHEN 2 THEN ' - Getting cozy with '
      END AS beginning,
      CASE (
          ABS(RANDOM()) % (3 - 0) + 0
      )
      -- ABS(RANDOM()) % (HIGH - LOW) + LOW
        WHEN 0 THEN 'sql'
        WHEN 1 THEN 'javascript'
        WHEN 2 THEN 'python'
      END AS language,
      CASE (
          ABS(RANDOM()) % (6 - 0) + 0
      )
      -- ABS(RANDOM()) % (HIGH - LOW) + LOW
        WHEN 0 THEN ' so you can change the world.'
        WHEN 1 THEN ' so you can find yourself.'
        WHEN 2 THEN ' so you can reach self-actualization.'
        WHEN 3 THEN ' so you can become a snob.'
        WHEN 4 THEN ' so you can write more code.'
        WHEN 5 THEN ' for fun and profit.'
      END AS reason   
  FROM cnt
)
SELECT title || beginning || language || reason
FROM parts
`
