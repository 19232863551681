import React from "react"
// import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  EuiCallOut,
  EuiTitle,
  EuiText,
  EuiCodeBlock,
  EuiSpacer,
  EuiPageBody,
  EuiPageContent,
  EuiListGroup,
  EuiListGroupItem,
} from "@elastic/eui"
import { LessonTableOfContents } from "src/components"

import styled from "styled-components"
import { ChallengeTypes } from "src/types"

const LessonContainer = styled.div`
  display: flex;
  position: relative;
`
const LessonWrapper = styled.div``
// const LessonBody = styled.div`
//   /* Inline code */
//   & p > code,
//   span > code {
//     display: inline-block;
//     padding: 0 4px;
//     border-radius: 0.3rem;
//     // word-break: break-all !important;
//     // white-space: pre-wrap;
//     background: #e3eaf6;
//     color: ${(props) => props.theme.euiCodeBlockStringColor};
//   }

//   & span > ul {
//     margin-top: 8px;
//   }
// `
const LessonBody = styled.div`
  /* Inline code */
  & p > code,
  span > code {
    display: inline-block;
    border-radius: 4px;
    color: #7c609e;
    white-space: normal;
    line-height: 1.5;
    letter-spacing: normal;
    padding: 0 8px;
    background: #f5f7fa;
    font-weight: 700;
  }

  & span > ul {
    margin-top: 8px;
  }
`
const StyledP = styled.p`
  margin-top: 1.714rem;
  line-height: 1.9rem;
`
const StyledStrong = styled.strong``
const StyledH1 = styled.h1``
const StyledH2 = styled.h2`
  margin: 1.5rem 0;
`
const StyledH3 = styled.h3``
const StyledH4 = styled.h4``
const StyledH5 = styled.h5``
const StyledH6 = styled.h6``
const StyledPre = styled.pre``
// const StyledOl = styled.ol``
// const StyledUl = styled.ul``
// const StyledLi = styled.li``
const StyledEuiCallOut = styled(EuiCallOut)`
  margin-top: 1.714rem;

  & p {
    margin-top: 0;
    margin-bottom: 1.714rem;
  }
`
const StyledEuiListGroup = styled(EuiListGroup)`
  margin-top: 1.714rem;
`
const StyledEuiListGroupItem = styled(EuiListGroupItem)`
  & > span {
    align-items: flex-start;
  }

  & svg {
    margin-top: 3px;
  }
`

const CodeBlock: React.FC = ({ children, ...props }) => (
  <>
    <EuiSpacer size="l" />
    <EuiCodeBlock language="sql" fontSize="m" paddingSize="m" overflowHeight={500} isCopyable {...props}>
      {children}
    </EuiCodeBlock>
    <EuiSpacer size="s" />
  </>
)
const ListItem: React.FC = ({ children, ...props }) => (
  <StyledEuiListGroupItem wrapText label={children} iconType="dot" {...props}>
    {children}
  </StyledEuiListGroupItem>
)
const UnorderedList: React.FC = ({ children, ...props }) => (
  <StyledEuiListGroup maxWidth={false} {...props}>
    <>{children}</>
  </StyledEuiListGroup>
)
const OrderedList: React.FC = ({ children, ...props }) => (
  <StyledEuiListGroup maxWidth={false} {...props}>
    <>{children}</>
  </StyledEuiListGroup>
)
const TextNodeP: React.FC = ({ children, ...props }) => (
  <EuiText {...props}>
    <StyledP>{children}</StyledP>
  </EuiText>
)
const TextNodeStrong: React.FC = ({ children, ...props }) => <StyledStrong {...props}>{children}</StyledStrong>
const TitleNodeH1: React.FC = ({ children, ...props }) => (
  <EuiTitle {...props}>
    <StyledH1>{children}</StyledH1>
  </EuiTitle>
)
const TitleNodeH2: React.FC = ({ children, ...props }) => (
  <EuiTitle {...props}>
    <StyledH2>{children}</StyledH2>
  </EuiTitle>
)
const TitleNodeH3: React.FC = ({ children, ...props }) => (
  <EuiTitle {...props}>
    <StyledH3>{children}</StyledH3>
  </EuiTitle>
)
const TitleNodeH4: React.FC = ({ children, ...props }) => (
  <EuiTitle {...props}>
    <StyledH4>{children}</StyledH4>
  </EuiTitle>
)
const TitleNodeH5: React.FC = ({ children, ...props }) => (
  <EuiTitle {...props}>
    <StyledH5>{children}</StyledH5>
  </EuiTitle>
)
const TitleNodeH6: React.FC = ({ children, ...props }) => (
  <EuiTitle {...props}>
    <StyledH6>{children}</StyledH6>
  </EuiTitle>
)
const BlockQuote: React.FC = ({ children, ...props }) => (
  <StyledEuiCallOut
    title="INFO:"
    // title="Check it out, here's a really long title that will wrap within a narrower browser"
    iconType="iInCircle"
    {...props}
  >
    <span>{children}</span>
  </StyledEuiCallOut>
)
// const StyledLink = styled((props) => <Link {...props} />)`
//   font-size: 16px;
// `
const StyledLink = styled.a`
  font-size: 16px;
  color: #0077cc !important;

  &:hover {
    text-decoration: underline;
  }
`
const LinkNode: React.FC = ({ children, ...props }) => (
  <StyledLink rel="noopener noreferrer" target="_blank" {...props}>
    <>{children}</>
  </StyledLink>
)

const shortcodes = {
  a: LinkNode,
  p: TextNodeP,
  h1: TitleNodeH1,
  h2: TitleNodeH2,
  h3: TitleNodeH3,
  h4: TitleNodeH4,
  h5: TitleNodeH5,
  h6: TitleNodeH6,
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  strong: TextNodeStrong,
  blockquote: BlockQuote,
  // video: PostContentVideo,
  pre: StyledPre,
  code: CodeBlock,
}

interface ILesson {
  body: string
  toc: ChallengeTypes.TableOfContents
}
export default function Lesson({ body, toc }: ILesson) {
  return (
    <LessonContainer>
      <LessonTableOfContents toc={toc} />
      <EuiPageBody>
        <EuiPageContent>
          <LessonWrapper>
            <LessonBody>
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </LessonBody>
          </LessonWrapper>
        </EuiPageContent>
      </EuiPageBody>
      <EuiSpacer size="xl" />
    </LessonContainer>
  )
}
