import React from "react"
import * as R from "ramda"
import { navigate } from "gatsby"
import { ChallengeHelpPanel, EditorTasksAndResultsTable, Lesson } from "src/components"
import {
  EuiButton,
  EuiPage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTabs,
  EuiTab,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui"
import { useCodeEditor, useCurrentChallengeTasks, useToasts, useUniqueId } from "src/hooks"
import { useSqliteDatabaseContext } from "src/context/sqliteDb"
import { sleep } from "src/utils/async"
import styled from "styled-components"

import { CommonTypes, ChallengeTypes, SQLiteDbTypes, UiTypes } from "src/types"

interface IChallengeContainer {
  challenge: ChallengeTypes.ChallengeNodeFull
  prevNode?: ChallengeTypes.ChallengeNode
  nextNode?: ChallengeTypes.ChallengeNode
  slug: CommonTypes.Slug
  pageSize?: number
}
export default function ChallengeContainer({
  challenge,
  prevNode,
  nextNode,
  slug,
  pageSize = 10,
}: IChallengeContainer) {
  const { databaseName, challengeId } = challenge.frontmatter
  const { tasks, taskState, activeTaskId, completeTask } = useCurrentChallengeTasks(challengeId)
  const challengeCompleted = Boolean(activeTaskId && activeTaskId >= tasks?.length)

  const [failed, setFailed] = React.useState(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [results, setResults] = React.useState<SQLiteDbTypes.SQLiteQueryResults>(null)
  const [err, setErr] = React.useState<string | null>(null)
  const [_, { addToast }] = useToasts()
  const dbContext = useSqliteDatabaseContext()

  if (!R.isNil(dbContext)) {
    const { db, moviesDb, countriesDb, olympicsDb, usersDb, blogDb, initializing, refreshDb } = dbContext

    const dbToExec = { countriesDb, moviesDb, olympicsDb, usersDb, blogDb, db }[databaseName]

    const refreshDatabase = () => refreshDb(databaseName)

    if (initializing || R.isNil(dbToExec)) return null

    const exec = async (sql: string) => {
      setIsProcessing(true)

      try {
        const results = dbToExec.exec(sql || "") // an array of objects is returned
        await sleep(500)

        // run task test here
        if (!R.isNil(challengeId)) {
          const tester = tasks[activeTaskId]?.test
          if (!R.isNil(tester)) {
            if (results) {
              const passed = tester({ results, db: dbToExec })
              if (passed) {
                setFailed(false)
                completeTask(activeTaskId)
                const toast: UiTypes.Toast = {
                  id: useUniqueId("toast"),
                  type: UiTypes.ToastType.SUCCESS,
                  title: `Success!`,
                  contents: `Task completed.`,
                }
                addToast(toast)
              } else {
                setFailed(true)
              }
            }
          }
        }

        setResults(results)
        setErr(null)
      } catch (err) {
        // console.log({ err })
        // exec throws an error when the SQL statement is invalid
        setErr(err)
      } finally {
        setIsProcessing(false)
      }
    }

    return (
      <>
        <Challenge
          refreshDb={refreshDatabase}
          challenge={challenge}
          nextNode={nextNode}
          prevNode={prevNode}
          slug={slug}
          exec={exec}
          err={err}
          failed={failed}
          results={results}
          isProcessing={isProcessing}
          pageSize={pageSize}
          challengeCompleted={challengeCompleted}
          tasks={tasks}
          taskState={taskState}
          completeTask={completeTask}
        />
      </>
    )
  }

  return null
}

const ChallengeWrapper = styled.div`
  background: #fafbfd;
  & .error {
    margin-top: 1rem;
    color: red;
  }
`

const tabs = [
  {
    id: "lesson",
    name: (
      <span>
        <EuiIcon type="indexEdit" />
        &nbsp;Lesson
      </span>
    ),
    disabled: false,
  },
  {
    id: "challenge",
    name: (
      <span>
        <EuiIcon type="database" />
        &nbsp;Challenge
      </span>
    ),
    disabled: false,
  },
  {
    id: "help",
    name: (
      <span>
        <EuiIcon type="help" />
        &nbsp;Help
      </span>
    ),
    disabled: false,
  },
]

interface IChallenge extends SQLiteDbTypes.ISQLiteContextConsumer {
  challenge: ChallengeTypes.ChallengeNodeFull
  prevNode?: ChallengeTypes.ChallengeNode
  nextNode?: ChallengeTypes.ChallengeNode
  slug: CommonTypes.Slug
  results: SQLiteDbTypes.SQLiteQueryResults
  exec: (sql: string) => Promise<void>
  pageSize: number
  failed?: boolean
  challengeCompleted?: boolean
  tasks: ChallengeTypes.Task[]
  taskState: ChallengeTypes.TaskCompletion
  completeTask: (taskId: number) => void
  // refreshDb: (databasename: SQLiteDbTypes.DatabaseName) => void
  refreshDb: () => void
}
const Challenge = React.memo(
  ({
    err = null,
    exec,
    initializing,
    isProcessing = false,
    results,
    challenge,
    prevNode,
    nextNode,
    // slug,
    refreshDb,
    failed,
    challengeCompleted = false,
    tasks,
    taskState,
    completeTask,
  }: IChallenge) => {
    const challengeId = challenge.frontmatter.challengeId
    const [selectedTabId, setSelectedTabId] = React.useState("lesson")
    const [codeText, { setCodeText }] = useCodeEditor(challengeId)

    const handleOnExecute = async () => {
      await exec(codeText)
    }

    const onSelectedTabChanged = (id: string) => {
      setSelectedTabId(id)
    }

    const renderTabs = () => {
      return tabs.map((tab, index) => (
        <EuiTab
          onClick={() => onSelectedTabChanged(tab.id)}
          isSelected={tab.id === selectedTabId}
          disabled={tab.disabled}
          key={index}
        >
          {tab.name}
        </EuiTab>
      ))
    }

    const handleOnCodeEditorTextChange = (text: string) => {
      setCodeText(text, challengeId)
    }

    const helpContent = (
      <>
        <ChallengeHelpPanel challenge={challenge} />
      </>
    )

    const lessonContent = (
      <>
        <Lesson body={challenge.body} toc={challenge.tableOfContents} />
      </>
    )

    const challengeContent = (
      <EditorTasksAndResultsTable
        refreshDb={refreshDb}
        initializing={Boolean(initializing)}
        codeText={codeText}
        handleOnCodeEditorTextChange={handleOnCodeEditorTextChange}
        handleOnExecute={handleOnExecute}
        isProcessing={isProcessing}
        err={err}
        failed={failed}
        tasks={tasks}
        taskState={taskState}
        completeTask={completeTask}
        results={results}
      />
    )

    return (
      <ChallengeWrapper>
        <EuiPage restrictWidth direction="column">
          <EuiSpacer size="m" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiTitle>
                <h1>
                  {challenge?.frontmatter?.challengeId} - {challenge?.frontmatter?.title}
                </h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem>
                  {prevNode ? (
                    <EuiButton onClick={() => navigate(`/challenges${prevNode.fields.slug}`)}>Prev Challenge</EuiButton>
                  ) : null}
                </EuiFlexItem>
                <EuiFlexItem>
                  {nextNode ? (
                    <EuiButton
                      isDisabled={!challengeCompleted}
                      onClick={() => navigate(`/challenges${nextNode.fields.slug}`)}
                    >
                      Next Challenge
                    </EuiButton>
                  ) : null}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          <EuiTabs size="l">{renderTabs()}</EuiTabs>
        </EuiPage>
        <EuiPage restrictWidth direction="column">
          {selectedTabId === "lesson" ? lessonContent : null}
          {selectedTabId === "challenge" ? challengeContent : null}
          {selectedTabId === "help" ? helpContent : null}
        </EuiPage>
      </ChallengeWrapper>
    )
  }
)
