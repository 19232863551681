import { ID, Slug, Datetime, Tag, Category } from "src/types/common"
import { SQLiteQueryResults, DatabaseName, SQLiteDatabase } from "src/types/sqliteDb"
import { PURGE, REHYDRATE } from "redux-persist"

export interface ChallengeFields {
  slug: Slug
  date: Datetime
  filePath: string
}
export interface ChallengeFrontmatter {
  id?: ID
  challengeId: string
  databaseName: DatabaseName
  slug: Slug
  title: string
  tags: Tag[]
  date: Datetime
  category: Category
  description?: string
  published?: boolean
}
export interface ChallengeNode {
  fields: ChallengeFields
  frontmatter: ChallengeFrontmatter
  body?: any
}
export interface TableOfContents {
  items: Array<{ url: string; title: string }>
}
export interface ChallengeNodeFull extends ChallengeNode {
  wordCount: {
    words: number
    sentences: number
    paragraphs: number
  }
  tableOfContents: TableOfContents
  headings: {
    value: string
    depth: number
  }
  excerpt: string
  timeToRead: number | string
}

export interface Task {
  instructions: string
  test: (p: { results: SQLiteQueryResults; db: SQLiteDatabase | null; retainOrder?: boolean }) => boolean
  codeSample?: string
}

export interface TaskCompletion {
  tasks: Record<ID, { completed: boolean; timestamp?: Datetime }>
  currentTask: number
}
export interface ChallengeState {
  data: Record<ID, TaskCompletion>
}

export enum ChallengesActionType {
  COMPLETE_TASK = "@@challenges/COMPLETE_TASK",
  CLEAR_PROGRESS = "@@challenges/CLEAR_PROGRESS",
}

export interface ICompleteTask {
  type: ChallengesActionType.COMPLETE_TASK
  challengeId: string
  taskId: number
}

export interface IClearProgress {
  type: ChallengesActionType.CLEAR_PROGRESS
}

export interface IPurge {
  type: typeof PURGE
  key: string
  result: () => void
}
export interface IRehydrate {
  type: typeof REHYDRATE
}

export type ChallengeAction = ICompleteTask | IClearProgress | IPurge | IRehydrate

export type CompletedTasksMapping = Record<string, Array<Record<string, string | boolean>>>
